import { useApolloClient } from "../../composables/useApolloClient";
import { ALL_SUBCONTRACTORS } from "../../graphql/queries";
import {
  CREATE_SUBCONTRACTOR,
  UPDATE_SUBCONTRACTOR,
} from "../../graphql/mutations";

const apollo = useApolloClient();

export default {
  state: {
    subcontractor: null,
    subcontractors: null,
  },
  getters: {
    getSubcontractor(state) {
      return state.subcontractor;
    },
    getSubcontractors(state) {
      return state.subcontractors;
    },
  },
  mutations: {
    setSubcontractor(state, payload) {
      state.subcontractor = payload;
    },
    setSubcontractors(state, payload) {
      state.subcontractors = payload;
    },
    addSubcontractor(state, payload) {
      state.subcontractors = state.subcontractors.concat(payload);
    },
    updateSubcontractor(state, payload) {
      const subcontractorInd = state.subcontractors.findIndex(
        (m) => m.key === payload.key
      );
      state.subcontractors[subcontractorInd] = payload;
    },
    deleteSubcontractor(state, payload) {
      state.subcontractors = state.subcontractors.filter(
        (r) => r.key !== payload
      );
    },
  },
  actions: {
    async getAllSubcontractors({ commit, rootState }) {
      const { data } = await apollo.client.query({
        query: ALL_SUBCONTRACTORS,
        variables: {
          filter: {
            workKey: rootState.work.work.key,
          },
          perPage: 500,
        },
      });

      let subcontactors = JSON.parse(
        JSON.stringify(data.allSubcontractors.subcontractors)
      );
      commit("setSubcontractors", subcontactors);
    },
    async createSubcontractor({ commit, rootState }, { subcontractor }) {
      const { data } = await apollo.client.mutate({
        mutation: CREATE_SUBCONTRACTOR,
        variables: {
          input: {
            ...subcontractor,
            workKey: rootState.work.work.key,
          },
        },
      });

      commit("addSubcontractor", data.createSubcontractor);
    },
    async updateSubcontractor({ commit }, { subcontractor }) {
      const { data } = await apollo.client.mutate({
        mutation: UPDATE_SUBCONTRACTOR,
        variables: {
          input: subcontractor,
        },
      });

      commit("updateSubcontractor", data.updateSubcontractor);
    },
  },
};

import { useApolloClient } from "../../composables/useApolloClient";
import {
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  CREATE_GROUP_MEMBER,
  DELETE_GROUP_MEMBER,
  SAVE_GROUP_DEPARTURES,
  SAVE_GROUP_SECTORS,
} from "../../graphql/mutations";
import {
  ALL_GROUPS,
  LIST_GROUPS,
  FIND_GROUP_DEPARTURES,
  FIND_GROUP_SECTORS,
} from "../../graphql/queries";

const apollo = useApolloClient();

const categoriesNameMap = {
  Capataz: 4,
  Operario: 3,
  Oficial: 2,
  Ayudante: 1,
  Ninguna: 0,
};

const orderByCategoryAndLastName = (members) => {
  return members.sort((a, b) => {
    let categoryA = categoriesNameMap[a.user.category?.name || "Ninguna"];
    let categoryB = categoriesNameMap[b.user.category?.name || "Ninguna"];

    if (categoryA < categoryB) {
      return 1;
    } else if (categoryA > categoryB) {
      return -1;
    } else {
      return `${a.user.lastName} ${a.user.lastName2}`.localeCompare(
        `${b.user.lastName} ${b.user.lastName}`
      );
    }
  });
};

export default {
  state: {
    groups: null,
    listGroups: null,
  },
  getters: {
    getGroups(state) {
      return state.groups;
    },
    getListGroups(state) {
      return state.listGroups;
    },
  },
  mutations: {
    setGroups(state, payload) {
      state.groups = payload;
    },
    addGroup(state, payload) {
      state.groups = state.groups.concat(payload);
    },
    updateGroup(state, payload) {
      const groupInd = state.groups.findIndex((g) => g.key === payload.key);
      state.groups[groupInd] = { ...state.groups[groupInd], ...payload };
    },
    updateGroupTotalMembers(state, payload) {
      const groupInd = state.groups.findIndex((g) => g.key === payload.key);
      state.groups[groupInd] = {
        ...state.groups[groupInd],
        total_members:
          state.groups[groupInd].total_members +
          (payload.action === "increment" ? 1 : -1),
      };
    },
    deleteGroup(state, payload) {
      state.groups = state.groups.filter((g) => g.key !== payload);
    },
    addGroupMember(state, payload) {
      const groupInd = state.groups.findIndex(
        (g) => g.key === payload.groupKey
      );
      state.groups[groupInd].members.push(payload.member);
    },
    deleteGroupMember(state, payload) {
      const groupInd = state.groups.findIndex(
        (g) => g.key === payload.groupKey
      );
      state.groups[groupInd].members = state.groups[groupInd].members.filter(
        (m) => m.key !== payload.key
      );
    },
    setListGroups(state, payload) {
      state.listGroups = payload;
    },
  },
  actions: {
    async getListAllGroups({ commit, rootState }) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
        };

        const user = rootState.user.userAuthenticated;
        if (user?.type !== "Admin") filter.foremanKey = user.key;

        const { data } = await apollo.client.query({
          query: LIST_GROUPS,
          variables: {
            filter: filter,
            perPage: 300,
          },
        });

        commit(
          "setListGroups",
          JSON.parse(JSON.stringify(data.allGroups.groups))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllGroups({ commit, rootState }, { workKey }) {
      try {
        const filter = {
          workKey: workKey,
        };

        const user = rootState.user.userAuthenticated;
        if (user?.type !== "Admin") filter.foremanKey = user.key;

        const { data } = await apollo.client.query({
          query: ALL_GROUPS,
          variables: {
            filter: filter,
            workKey: workKey,
            perPage: 500,
          },
        });

        let groups = JSON.parse(JSON.stringify(data.allGroups.groups));
        groups = groups.map((g) => ({
          ...g,
          members: orderByCategoryAndLastName(
            JSON.parse(JSON.stringify(g.memberConnection.members))
          ),
          total_members: g.memberConnection.pageInfo.total,
        }));

        commit("setGroups", groups);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createGroup({ commit, rootState }, { group }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_GROUP,
          variables: {
            input: group,
            workKey: rootState.work.work.key,
          },
        });

        commit("addGroup", { ...data.createGroup, members: [] });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateGroup({ commit, rootState }, { group }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_GROUP,
          variables: {
            input: group,
            workKey: rootState.work.work.key,
          },
        });

        commit("updateGroup", data.updateGroup);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteGroup({ commit }, { key }) {
      try {
        await apollo.client.mutate({
          mutation: DELETE_GROUP,
          variables: {
            key: key,
          },
        });

        commit("deleteGroup", key);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createGroupMember({ commit, rootState }, { groupKey, userKey }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_GROUP_MEMBER,
          variables: {
            input: { groupKey, userKey },
            workKey: rootState.work.work.key,
          },
        });

        commit("addGroupMember", {
          groupKey: groupKey,
          member: data.createGroupMember,
        });

        commit("updateGroupTotalMembers", {
          key: groupKey,
          action: "increment",
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteGroupMember({ commit }, { groupKey, key }) {
      try {
        await apollo.client.mutate({
          mutation: DELETE_GROUP_MEMBER,
          variables: {
            key: key,
          },
        });

        commit("deleteGroupMember", {
          groupKey: groupKey,
          key: key,
        });

        commit("updateGroupTotalMembers", {
          key: groupKey,
          action: "decrement",
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async saveGroupDepartures({}, { groupKey, departuresKey }) {
      await apollo.client.mutate({
        mutation: SAVE_GROUP_DEPARTURES,
        variables: {
          input: { groupKey, departuresKey },
        },
      });
    },
    // eslint-disable-next-line
    async getAllGroupDepartures({}, { groupKey }) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_GROUP_DEPARTURES,
          variables: {
            filter: {
              groupKey: groupKey,
            },
          },
        });

        return data.findGroupDepartures;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async saveGroupSectors({}, { groupKey, sectorsKey }) {
      await apollo.client.mutate({
        mutation: SAVE_GROUP_SECTORS,
        variables: {
          input: { groupKey, sectorsKey },
        },
      });
    },
    // eslint-disable-next-line
    async getAllGroupSectors({}, { groupKey }) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_GROUP_SECTORS,
          variables: {
            filter: {
              groupKey: groupKey,
            },
          },
        });

        return data.findGroupSectors;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

import { useApolloClient } from "../../composables/useApolloClient";
import { CREATE_SPECIALITY } from "../../graphql/mutations";
import { ALL_SPECIALITIES } from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    specialities: null,
    planeSpecialities: null,
  },
  getters: {
    getSpecialities(state) {
      return state.specialities;
    },
    getPlaneSpecialities(state) {
      return state.planeSpecialities;
    },
  },
  mutations: {
    setSpecialities(state, payload) {
      state.specialities = payload;
    },
    addSpeciality(state, payload) {
      state.specialities = state.specialities.concat(payload);
    },
    setPlaneSpecialities(state, payload) {
      state.planeSpecialities = payload;
    },
  },
  actions: {
    async getAllSpecialities({ commit }, { type }) {
      try {
        const { data } = await apollo.client.query({
          query: ALL_SPECIALITIES,
          variables: {
            perPage: 300,
            filter: { type },
          },
        });

        commit(
          type === "User" ? "setSpecialities" : "setPlaneSpecialities",
          JSON.parse(JSON.stringify(data.allSpecialities.specialities))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createSpeciality({ commit }, { work }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_SPECIALITY,
          variables: {
            input: work,
          },
        });

        commit("addSpeciality", data.createSpeciality);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

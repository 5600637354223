import { ErrorMessage, Field, Form } from "vee-validate";
import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import Alert from "./components/inc/Alert.vue";
import EndDrawer from "./components/inc/EndDrawer.vue";
import LoadingButtonIcon from "./components/inc/LoadingButtonIcon.vue";
import Modal from "./components/inc/Modal.vue";
import "./plugins/vee-validate";
import "./plugins/firebase";
import router from "./router";
import store from "./store";

// import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client/core";
// import { createApolloProvider } from "@vue/apollo-option";
// import { useApolloClient } from "./composables/useApolloClient";
// import { WebSocketLink } from "@apollo/client/link/ws";
// import { split } from "@apollo/client/core";
// import { getMainDefinition } from "@apollo/client/utilities";

// const httpLink = new HttpLink({
//   // You should use an absolute URL here
//   uri: process.env.VUE_APP_GRAPHQL_BASE_URL,
// });

// // Create the apollo client
// const apolloClient = new ApolloClient({
//   link: httpLink,
//   cache: new InMemoryCache(),
//   connectToDevTools: true,
// });

// // Create a provider
// const apolloProvider = createApolloProvider({
//   defaultClient: apolloClient,
// });

// .use(apolloProvider)

const app = createApp(App);

app.component("Form", Form);
app.component("Field", Field);
app.component("ErrorMessage", ErrorMessage);
app.component("Alert", Alert);
app.component("EndDrawer", EndDrawer);
app.component("LoadingButtonIcon", LoadingButtonIcon);
app.component("Modal", Modal);

app.config.globalProperties.$roles = {
  SUPER_ADMIN: "SuperAdmin",
  ADMIN: "Admin",
  WORKER: "Worker",
};

app.config.globalProperties.$modules = {
  TAREO: "Tareo",
  PLANIFICACION: "Planning",
  PLANOS: "Plane",
  //   CONFIGURATION: "Configuration",
  //   CRONOGRAMA: "Schedule",
};

app.use(store).use(router).mount("#app");

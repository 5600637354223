<template>
  <div
    class="
      w-72
      h-full
      pl-5
      shadow
      overflow-auto
      fixed
      z-10
      pt-14
      inset-y-0
      transform
      -translate-x-full
      transition
      duration-200
      ease-in-out
      md:-translate-x-0
    "
  >
    <div class="pt-6 pb-2 pl-4">
      <div class="font-medium text-secondary hover:text-primary">
        <router-link :to="{ name: 'welcome' }" class="">Bienvenido</router-link>
      </div>
    </div>
    <div v-if="allowModule($modules.TAREO)" class="py-4">
      <h1 class="uppercase text-gray-500">Tareo y control de mano de obra</h1>
      <div
        class="
          flex flex-col
          pl-4
          normal-case
          font-medium
          text-secondary
          hover:text-primary
        "
      >
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'tasker-report' }">Reportes</router-link>
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'tasker-groups' }">Grupos</router-link>
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'tasker-departures' }"
            >Partidas</router-link
          >
        </div>
      </div>
    </div>
    <div v-if="allowModule($modules.TAREO)" class="py-4">
      <h1 class="uppercase text-gray-500">Productividad</h1>
      <div
        class="
          flex flex-col
          pl-4
          normal-case
          font-medium
          text-secondary
          hover:text-primary
        "
      >
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'productivity-report' }"
            >Reportes</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'productivity-advances' }"
            >Avances</router-link
          >
        </div>
      </div>
    </div>
    <div
      v-if="
        allowModule($modules.PLANIFICACION) && showPlanificacion(getWork.key)
      "
      class="py-4"
    >
      <h1 class="uppercase text-gray-500">Planificación de obra</h1>
      <div
        class="
          flex flex-col
          pl-4
          normal-case
          font-medium
          text-secondary
          hover:text-primary
        "
      >
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'planning-report' }">Reportes</router-link>
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'planning-dashboard' }"
            >Crear Planificación</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'planning-restrictions' }"
            >Análisis de Restricciones</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'planning-compliance' }"
            >Causas de No Cumplimiento (CNC)</router-link
          >
        </div>
      </div>
    </div>
    <div v-if="allowModule($modules.PLANOS) && false" class="py-4">
      <h1 class="uppercase text-gray-500">Planos</h1>
      <div
        class="
          flex flex-col
          pl-4
          normal-case
          font-medium
          text-secondary
          hover:text-primary
        "
      >
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'plans-2d' }">2D</router-link>
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'plans-3d' }">3D</router-link>
        </div>
      </div>
    </div>
    <div
      v-if="getUserAuthenticated && getUserAuthenticated.type === roles.ADMIN"
      class="py-4"
    >
      <h1 class="uppercase text-gray-500">Configuración</h1>
      <div
        class="
          flex flex-col
          pl-4
          normal-case
          font-medium
          text-secondary
          hover:text-primary
        "
      >
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'configurations-users' }"
            >Agregar Usuario</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'configurations-workers' }"
            >Agregar Trabajador</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'configurations-categories' }"
            >Costos de Mano de Obra</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'configurations-subcontractors' }"
            >Subcontratistas</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'configurations-roles' }"
            >Roles</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'configurations-sectors' }"
            >Sectores/lotes</router-link
          >
        </div>
        <div class="font-medium text-secondary hover:text-primary py-2">
          <router-link :to="{ name: 'configurations-jornal' }"
            >Jornal Típico</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";

const store = useStore();
const app = getCurrentInstance();
const roles = app.appContext.config.globalProperties.$roles;

const getWork = computed(() => store.getters.getWork);
const getUserAuthenticated = computed(() => store.getters.getUserAuthenticated);
const getUserPermissions = computed(() => store.getters.getUserPermissions);

const allowModule = (module) => {
  return (
    getUserAuthenticated.value.type === roles.ADMIN ||
    getUserPermissions.value?.find(
      (p) => p.module === module && p.hasWebPermissions
    )
  );
};

const showPlanificacion = (workId) =>
  [
    "5829c862-a149-4a03-9682-bb0e27a6b46f",
    "ea63fd0b-99e7-4081-b7cc-1c8b510b4f2a",
  ].includes(workId);
</script>

import { defineRule } from "vee-validate";
import { email, required, regex } from "@vee-validate/rules";

defineRule("email", (value) => {
  if (!required(value)) return "El campo es obligatorio";
  if (!email(value)) return "El correo electrónico es inválido";
  return true;
});

defineRule("email-optional", (value) => {
  if (!email(value)) return "El correo electrónico es inválido";
  return true;
});

defineRule("required", (value) => {
  if (!required(value)) return "El campo es obligatorio";
  return true;
});

defineRule("decimal", (value) => {
  if (!regex(value, { regex: /^\d+(\.(\d+))?$/ }))
    return "El campo debe ser un número";
  return true;
});

import { useApolloClient } from "../../composables/useApolloClient";
import {
  ALL_DEPARTURES_IP,
  GENERATE_DEPARTURE_IP_REPORT,
  GENERATE_DEPARTURE_IP_RESUME,
  GENERATE_DEPARTURE_IP_RESUME_BY_STAGES,
} from "../../graphql/queries";
import {
  CREATE_DEPARTURE_IP,
  UPDATE_DEPARTURE_IP,
  GENERATE_DEPARTURE_IP_REPORT_EXCEL,
  GENERATE_DEPARTURE_IP_RESUME_EXCEL,
} from "../../graphql/mutations";

const apollo = useApolloClient();

const orderByDeparture = (departuresIp) => {
  return departuresIp.sort((a, b) => {
    const codeA = a.departure.code;
    const codeB = b.departure.code;
    const nameA = a.departure.name;
    const nameB = b.departure.name;

    if (!codeA) return 1;
    if (!codeB) return -1;

    if (codeA < codeB) return -1;
    if (codeA > codeB) return 1;

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0; //
  });
};

export default {
  state: {
    departureIpReport: null,
    departuresIp: null,
    departuresIpInfo: null,
    departuresIpResume: null,
    departuresIpResumeDetail: null,
    departuresIpResumeByStages: null,
    departuresIpResumeByStagesInfo: null,
    departuresIpResumeByStagesDetail: null,
  },
  getters: {
    getDepartureIpReport(state) {
      return state.departureIpReport;
    },
    getDeparturesIp(state) {
      return state.departuresIp;
    },
    getDeparturesIpInfo(state) {
      return state.departuresIpInfo;
    },
    getDeparturesIpResume(state) {
      return state.departuresIpResume;
    },
    getDeparturesIpResumeDetail(state) {
      return state.departuresIpResumeDetail;
    },
    getDeparturesIpResumeByStages(state) {
      return state.departuresIpResumeByStages;
    },
    getDeparturesIpResumeByStagesInfo(state) {
      return state.departuresIpResumeByStagesInfo;
    },
    getDeparturesIpResumeDetailByStages(state) {
      return state.departuresIpResumeDetailByStages;
    },
  },
  mutations: {
    setDepartureIpReport(state, payload) {
      state.departureIpReport = payload;
    },
    setDeparturesIp(state, payload) {
      state.departuresIp = payload;
    },
    setDeparturesIpInfo(state, payload) {
      state.departuresIpInfo = payload;
    },
    setDeparturesIpResume(state, payload) {
      state.departuresIpResume = payload;
    },
    setDeparturesIpResumeDetail(state, payload) {
      state.departuresIpResumeDetail = payload;
    },
    setDeparturesIpResumeByStages(state, payload) {
      state.departuresIpResumeByStages = payload;
    },
    setDeparturesIpResumeByStagesInfo(state, payload) {
      state.departuresIpResumeByStagesInfo = payload;
    },
    setDeparturesIpResumeDetailByStages(state, payload) {
      state.departuresIpResumeDetailByStages = payload;
    },
  },
  actions: {
    async getAllDeparturesIp({ commit, rootState }, filter) {
      try {
        const { data } = await apollo.client.query({
          query: ALL_DEPARTURES_IP,
          variables: {
            filter: {
              ...filter,
              workKey: rootState.work.work.key,
            },
            perPage: 500,
          },
        });

        const result = JSON.parse(JSON.stringify(data.allDeparturesIp));
        commit("setDeparturesIp", result?.departuresIp);
        commit("setDeparturesIpInfo", {
          departuresName: result?.departuresName,
          stagesName: result?.stagesName,
          totalDepartures: result?.totalDepartures,
          totalSectors: result?.totalSectors,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createDepartureIp({ rootState }, { departureIp }) {
      const { data } = await apollo.client.mutate({
        mutation: CREATE_DEPARTURE_IP,
        variables: {
          input: {
            ...departureIp,
            workKey: rootState.work.work.key,
          },
        },
      });

      // commit("updateWorkCategory", data.createDepartureIp);
      return data.createDepartureIp;
    },
    // eslint-disable-next-line
    async updateDepartureIp({}, { departureIp }) {
      const { data } = await apollo.client.mutate({
        mutation: UPDATE_DEPARTURE_IP,
        variables: {
          input: departureIp,
        },
      });

      // commit("updateWorkCategory", data.createDepartureIp);
      return data.updateDepartureIp;
    },
    resetDeparturesIp({ commit }) {
      commit("setDeparturesIp", null);
    },
    async generateDepartureIpReport(
      { commit, rootState },
      { departureKey, dateTo, dateFrom }
    ) {
      try {
        const { data } = await apollo.client.query({
          query: GENERATE_DEPARTURE_IP_REPORT,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
              departureKey: departureKey,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        commit(
          "setDepartureIpReport",
          JSON.parse(JSON.stringify(data.findDepartureIpReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetDepartureIpReport({ commit }) {
      commit("setDepartureIpReport", null);
    },
    async generateDepartureIpReportExcel(
      { rootState },
      { departureKey, dateTo, dateFrom }
    ) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_DEPARTURE_IP_REPORT_EXCEL,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
              departureKey: departureKey,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateDepartureIpReportExcel;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async generateDepartureIpResume(
      { commit, rootState },
      { dateFrom, dateTo, showDetail }
    ) {
      try {
        const { data } = await apollo.client.query({
          query: GENERATE_DEPARTURE_IP_RESUME,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        let departuresIpResume = JSON.parse(
          JSON.stringify(data.findDeparturesIpResume)
        );

        let resume = departuresIpResume.resume.map((di) => ({
          ...di,
          percentage:
            di.workHoursAccumulated &&
            di.departure.budgetHours &&
            di.departure.budgetHours > 0
              ? (di.workHoursAccumulated * 100) / di.departure.budgetHours
              : 0,
        }));

        let departures = resume
          .filter((r) => !r.departure.isSubDeparture)
          .map((r) => ({
            ...r,
            departure: { ...r.departure },
            budgetIp: r.departure.budgetIp,
          }));

        let subdepartures = resume.filter((r) => r.departure.isSubDeparture);
        subdepartures.forEach((sb) => {
          const ind = departures.findIndex(
            (d) => d.departureKey === sb.departure.parentDeparture.key
          );
          if (ind >= 0) {
            departures[ind].subdepartures = [
              ...(departures[ind].subdepartures || []),
              sb,
            ];
          } else {
            let newDeparture = {
              departureKey: sb.departure.parentDeparture.key,
              departure: sb.departure.parentDeparture,
              subdepartures: [sb],
            };
            departures.push(newDeparture);
          }

          let departureInd = ind >= 0 ? ind : departures.length - 1;

          let departure = departures[departureInd];

          let ipAccumulated = departure.ipAccumulated || 0;
          ipAccumulated += sb.ipAccumulated || 0;

          let budgetIp = departure.budgetIp || 0;
          budgetIp += sb.departure.budgetIp || 0;

          let hhWonLost = departure.hhWonLost || 0;
          hhWonLost += sb.hhWonLost || 0;

          let advanceAccumulated = departure.advanceAccumulated || 0;
          advanceAccumulated += sb.advanceAccumulated || 0;

          let workHoursAccumulated = departure.workHoursAccumulated || 0;
          workHoursAccumulated += sb.workHoursAccumulated || 0;

          departures[departureInd] = {
            ...departure,
            ipAccumulated,
            budgetIp,
            hhWonLost,
            advanceAccumulated,
            workHoursAccumulated,
          };
        });

        commit("setDeparturesIpResume", orderByDeparture(departures));

        if (showDetail)
          commit("setDeparturesIpResumeDetail", departuresIpResume.detail);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetDeparturesIpResume({ commit }) {
      commit("setDeparturesIpResume", null);
    },
    async generateDepartureIpResumeExcel({ rootState }, { dateFrom, dateTo }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_DEPARTURE_IP_RESUME_EXCEL,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateDepartureIpResumeExcel;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async generateDepartureIpResumeByStages(
      { commit, rootState },
      { dateFrom, dateTo, showDetail }
    ) {
      try {
        const { data } = await apollo.client.query({
          query: GENERATE_DEPARTURE_IP_RESUME_BY_STAGES,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        let departuresIpResume = JSON.parse(
          JSON.stringify(data.findDeparturesIpResumeByStages)
        );

        let onlyDeparturesName = [
          ...new Set(
            departuresIpResume.resume
              .filter(
                (r) =>
                  !r.departure.isSubDeparture || r.departure.parentDeparture
              )
              .map((d) =>
                d.departure.isSubDeparture
                  ? d.departure.parentDeparture.name
                  : d.departure.name
              )
          ),
        ];

        commit("setDeparturesIpResumeByStagesInfo", {
          stagesName: departuresIpResume.stagesName,
          departuresName: onlyDeparturesName,
        });

        let resume = departuresIpResume.resume
          //.filter((di) => di.stage && di.stage !== "")
          .map((di) => ({
            ...di,
            percentage:
              di.workHoursAccumulated &&
              di.departure.budgetHours &&
              di.departure.budgetHours > 0
                ? (di.workHoursAccumulated * 100) / di.departure.budgetHours
                : 0,
          }));

        let departures = resume
          //.filter((di) => di.stage && di.stage !== "")
          .filter((r) => !r.departure.isSubDeparture)
          .map((r) => ({
            ...r,
            departure: { ...r.departure },
            budgetIp: r.departure.budgetIp,
          }));

        let subdepartures = resume
          //.filter((di) => di.stage && di.stage !== "")
          .filter((r) => r.departure.isSubDeparture);
        subdepartures.forEach((sb) => {
          const ind = departures.findIndex(
            (d) => d.departureKey === sb.departure.parentDeparture.key
          );
          if (ind >= 0) {
            departures[ind].subdepartures = [
              ...(departures[ind].subdepartures || []),
              sb,
            ];
          } else {
            let newDeparture = {
              departureKey: sb.departure.parentDeparture.key,
              departure: sb.departure.parentDeparture,
              subdepartures: [sb],
            };
            departures.push(newDeparture);
          }

          let departureInd = ind >= 0 ? ind : departures.length - 1;

          let departure = departures[departureInd];

          let ipAccumulated = departure.ipAccumulated || 0;
          ipAccumulated += sb.ipAccumulated || 0;

          let budgetIp = departure.budgetIp || 0;
          budgetIp += sb.departure.budgetIp || 0;

          let hhWonLost = departure.hhWonLost || 0;
          hhWonLost += sb.hhWonLost || 0;

          let advanceAccumulated = departure.advanceAccumulated || 0;
          advanceAccumulated += sb.advanceAccumulated || 0;

          let workHoursAccumulated = departure.workHoursAccumulated || 0;
          workHoursAccumulated += sb.workHoursAccumulated || 0;

          departures[departureInd] = {
            ...departure,
            ipAccumulated,
            budgetIp,
            hhWonLost,
            advanceAccumulated,
            workHoursAccumulated,
          };
        });

        commit("setDeparturesIpResumeByStages", orderByDeparture(departures));

        if (showDetail)
          commit(
            "setDeparturesIpResumeDetailByStages",
            departuresIpResume.detail
          );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetDeparturesIpResumeByStages({ commit }) {
      commit("setDeparturesIpResumeByStages", null);
    },
  },
};

import { useApolloClient } from "../../composables/useApolloClient";
import {
  CONFIRM_SECOND_TASKER,
  CREATE_WORK,
  UPDATE_WORK,
  CONFIRM_SECOND_TASKER_DAILY,
  CREATE_TASKER_WEEKLY_CONFIG,
  UPDATE_TASKER_WEEKLY_CONFIG,
  SYNC_TASKER_WEEKLY_TO_CASISTEMAS,
} from "../../graphql/mutations";
import {
  FIND_TASKER_CONFIRMATION,
  FIND_TASKER_DAILY_CONFIRMATION,
  FIND_ALL_TASKER_DAILY_CONFIRMATIONS,
  FIND_TASKER_WEEKLY_CONFIG,
} from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    work: null,
    works: null,
    taskerConfirmation: null,
    taskerDailyConfirmation: null,
    taskerDailyConfirmations: null,
    taskerWeeklyConfig: null,
  },
  getters: {
    getWork(state) {
      return state.work;
    },
    getWorks(state) {
      return state.works;
    },
    getTaskerConfirmation(state) {
      return state.taskerConfirmation;
    },
    getTaskerDailyConfirmation(state) {
      return state.taskerDailyConfirmation;
    },
    getTaskerDailyConfirmations(state) {
      return state.taskerDailyConfirmations;
    },
    getTaskerWeeklyConfig(state) {
      return state.taskerWeeklyConfig;
    },
  },
  mutations: {
    setCurrentWork(state, payload) {
      state.currentWork = payload;
    },
    setWork(state, payload) {
      state.work = payload;
    },
    setWorks(state, payload) {
      state.works = payload;
    },
    addWork(state, payload) {
      state.works = state.works.concat(payload);
    },
    updateWork(state, payload) {
      const workInd = state.works.findIndex((m) => m.key === payload.key);
      state.works[workInd] = payload;

      if (state.work?.key === payload.key) state.work = payload;
    },
    setTaskerConfirmation(state, payload) {
      state.taskerConfirmation = payload;
    },
    setTaskerDailyConfirmation(state, payload) {
      state.taskerDailyConfirmation = payload;
    },
    setTaskerDailyConfirmations(state, payload) {
      state.taskerDailyConfirmations = payload;
    },
    setTaskerWeeklyConfig(state, payload) {
      state.taskerWeeklyConfig = payload;
    },
  },
  actions: {
    currentWorks({ commit, rootState }, { data }) {
      commit(
        "setShowStepper",
        rootState.user.userAuthenticated.type === "Admin" &&
          data.works &&
          !data.works.length
      );
      commit("setWorks", data.works);
    },
    getWorkByKey({ state }, { key }) {
      return state.works.find((w) => w.key === key);
    },
    setWork({ commit, dispatch, state }, { key }) {
      const work = state.works.find((w) => w.key === key);

      if (work) {
        commit("setWork", work);
        dispatch("setRoles", { data: [...work.roles] });
        dispatch("setUserPermissions", { workKey: work.key });
        commit("setShowMenu", true, { root: true });

        localStorage.setItem("work", key);
      }
    },
    async createWork({ commit }, { work }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_WORK,
          variables: {
            input: work,
          },
        });

        commit("addWork", data.createWork);
        commit("setWork", data.createWork);

        commit("setRoles", data.createWork.roles, { root: true });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateWork({ commit }, { work }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_WORK,
          variables: {
            input: work,
          },
        });

        commit("updateWork", data.updateWork);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetWork({ commit }) {
      commit("setWork", null);
      commit("setRoles", null, { root: true });
      commit("setDepartures", null, { root: true });
      commit("setSectors", null, { root: true });
      commit("setGroups", null, { root: true });
      commit("setMembers", null, { root: true });
      commit("setForemans", null, { root: true });
      commit("setRestrictions", null, { root: true });
      commit("setSpecialities", null, { root: true });
      commit("setSchedule", null, { root: true });
      commit("setSchedules", null, { root: true });
      commit("setScheduleNotCompleted", null, { root: true });
      commit("setDaySchedule", null, { root: true });
      commit("setTaskerConfirmation", null);
      commit("setTaskerDailyConfirmation", null);
      commit("setTaskerWeeklyConfig", null);

      localStorage.removeItem("work");
    },
    async findTaskerConfirmation({ commit, state }, { date }) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_TASKER_CONFIRMATION,
          variables: {
            filter: {
              workKey: state.work.key,
              date: date,
            },
          },
        });

        commit("setTaskerConfirmation", data.findTaskerConfirmation);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async findTaskerDailyConfirmation(
      { commit, state, rootState },
      { date, type }
    ) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_TASKER_DAILY_CONFIRMATION,
          variables: {
            filter: {
              workKey: state.work.key,
              userKey: rootState.user.userAuthenticated.key,
              date: date,
              type: type,
            },
          },
        });

        commit("setTaskerDailyConfirmation", data.findTaskerDailyConfirmation);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async findAllTaskerDailyConfirmations({ commit, state }, { date }) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_ALL_TASKER_DAILY_CONFIRMATIONS,
          variables: {
            filter: {
              workKey: state.work.key,
              date: date,
              type: "First",
            },
          },
        });

        commit(
          "setTaskerDailyConfirmations",
          data.findAllTaskerDailyConfirmations
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async confirmSecondTasker({ commit, state, rootState }, { date }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CONFIRM_SECOND_TASKER,
          variables: {
            input: {
              workKey: state.work.key,
              email: rootState.user.userAuthenticated.email,
              date: date,
            },
          },
        });

        commit("setTaskerConfirmation", data.confirmSecondTasker);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async confirmSecondTaskerDaily({ commit, state, rootState }, { date }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CONFIRM_SECOND_TASKER_DAILY,
          variables: {
            input: {
              workKey: state.work.key,
              userKey: rootState.user.userAuthenticated.key,
              userEmail: rootState.user.userAuthenticated.email,
              date: date,
              type: "Second",
            },
          },
        });

        commit("setTaskerDailyConfirmation", data.confirmSecondTaskerDaily);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetTaskerConfirmation({ commit }) {
      commit("setTaskerConfirmation", null);
    },
    async findTaskerWeeklyConfig({ commit, state }, { initialDate }) {
      const { data } = await apollo.client.query({
        query: FIND_TASKER_WEEKLY_CONFIG,
        variables: {
          filter: {
            workKey: state.work.key,
            initialDate,
          },
        },
      });

      commit("setTaskerWeeklyConfig", data.findTaskerWeeklyConfig);
    },
    async createTaskerWeeklyConfig({ commit, state }, { config }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_TASKER_WEEKLY_CONFIG,
          variables: {
            input: {
              workKey: state.work.key,
              ...config,
            },
          },
        });

        commit("setTaskerWeeklyConfig", data.createTaskerWeeklyConfig);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateTaskerWeeklyConfig({ commit, state }, { config }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_TASKER_WEEKLY_CONFIG,
          variables: {
            input: config,
          },
        });

        commit("setTaskerWeeklyConfig", {
          ...state.taskerWeeklyConfig,
          ...data.updateTaskerWeeklyConfig,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetTaskerWeeklyConfig({ commit }) {
      commit("setTaskerWeeklyConfig", null);
    },
    async syncTaskerWeeklyToCASistemas({ state }, { dateFrom, dateTo }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: SYNC_TASKER_WEEKLY_TO_CASISTEMAS,
          variables: {
            input: {
              dateFrom,
              dateTo,
              workKey: state.work.key,
            },
          },
        });

        return data.syncTaskerWeeklyToCASistemas;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

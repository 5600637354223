import { useApolloClient } from "../../composables/useApolloClient";
import { CREATE_ROLE, UPDATE_ROLE, DELETE_ROLE } from "../../graphql/mutations";

const apollo = useApolloClient();

export default {
  state: {
    role: null,
    roles: null,
  },
  getters: {
    getRole(state) {
      return state.role;
    },
    getRoles(state) {
      return state.roles;
    },
  },
  mutations: {
    setRole(state, payload) {
      state.role = payload;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    addRole(state, payload) {
      state.roles = state.roles.concat(payload);
    },
    updateRole(state, payload) {
      const roleInd = state.roles.findIndex((m) => m.key === payload.key);
      state.roles[roleInd] = payload;
    },
    deleteRole(state, payload) {
      state.roles = state.roles.filter((r) => r.key !== payload);
    },
  },
  actions: {
    setRoles({ commit }, { data }) {
      commit(
        "setRoles",
        data.map((w) => ({ ...w, permissions: [...(w.permissions || [])] }))
      );
    },
    async createRole({ commit }, { role }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_ROLE,
          variables: {
            input: role,
          },
        });

        commit("addRole", data.createRole);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateRole({ commit }, { role }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_ROLE,
          variables: {
            input: role,
          },
        });

        commit("updateRole", data.updateRole);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteRole({ commit }, { key }) {
      try {
        await apollo.client.mutate({
          mutation: DELETE_ROLE,
          variables: {
            key: key,
          },
        });

        commit("deleteRole", key);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

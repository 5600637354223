import { useApolloClient } from "../../composables/useApolloClient";
import {
  CREATE_PLAN,
  UPDATE_PLAN,
  DELETE_PLAN,
  CREATE_PLAN_DIARY,
  DELETE_PLAN_DIARY,
} from "@/graphql/mutations";
import {
  ALL_PLANS,
  ALL_PLANS_DIARIES,
  GENERATE_AUTODESK_ACCESS_TOKEN,
} from "@/graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    plan: null,
    plans: null,
    plansDiaries: null,
    plansDiariesByDate: {},
  },
  getters: {
    getPlan(state) {
      return state.plan;
    },
    getPlans(state) {
      return state.plans;
    },
    getPlansDiaries(state) {
      return state.plansDiaries;
    },
  },
  mutations: {
    setPlan(state, payload) {
      state.plan = payload;
    },
    setPlans(state, payload) {
      state.plans = payload;
    },
    addPlan(state, payload) {
      state.plans = state.plans.concat(payload);
    },
    updatePlan(state, payload) {
      const planInd = state.plans.findIndex((m) => m.key === payload.key);
      state.plans[planInd] = payload;
    },
    deletePlan(state, payload) {
      state.plans = state.plans.filter((r) => r.key !== payload);
    },
    setPlansDiaries(state, payload) {
      state.plansDiaries = payload;
    },
    addPlanDiary(state, payload) {
      state.plansDiaries = state.plansDiaries.concat(payload);
    },
    deletePlanDiary(state, payload) {
      state.plansDiaries = state.plansDiaries.filter((g) => g.key !== payload);
    },
    setPlansDiariesByDate(state, payload) {
      state.plansDiariesByDate[payload.date] = payload.plans;
    },
  },
  actions: {
    async getAllPlans(
      { commit, rootState },
      { specialityKey, type, resetPlans = true }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
        };

        if (specialityKey) filter.specialityKey = specialityKey;
        if (type) filter.type = type;

        const { data } = await apollo.client.query({
          query: ALL_PLANS,
          variables: {
            filter: filter,
            perPage: 300,
          },
        });

        if (resetPlans)
          commit("setPlans", JSON.parse(JSON.stringify(data.allPlanes.planes)));

        return JSON.parse(JSON.stringify(data.allPlanes.planes));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createPlan({ commit, rootState }, { plan }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_PLAN,
          variables: {
            input: {
              name: plan.name,
              type: plan.type,
              specialityKey: plan.specialityKey,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("addPlan", data.createPlane);

        return data.createPlane;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updatePlan({ commit, rootState }, { plan }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_PLAN,
          variables: {
            input: {
              ...plan,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("updatePlan", data.updatePlane);

        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllPlansDiaries({ commit, rootState }, { date }) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          date: date,
        };

        const { data } = await apollo.client.query({
          query: ALL_PLANS_DIARIES,
          variables: {
            filter: filter,
            perPage: 300,
          },
        });

        commit(
          "setPlansDiaries",
          JSON.parse(JSON.stringify(data.allPlaneDiaries.planeDiaries))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createPlanDiary({ commit, rootState }, { planDiary }) {
      try {
        const body = new FormData();
        body.append(
          "operations",
          JSON.stringify({
            query: CREATE_PLAN_DIARY,
            variables: {
              input: {
                workKey: rootState.work.work.key,
                specialityKey: planDiary.specialityKey,
                legend: planDiary.legend,
                date: planDiary.date,
                file: null,
              },
            },
          })
        );
        body.append("map", '{ "0": [ "variables.input.file" ]}');
        body.append("0", new Blob([planDiary.file]), "file.png");

        const response = await fetch(process.env.VUE_APP_GRAPHQL_BASE_URL, {
          body: body,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "POST",
        });

        const data = await response.json();
        if (data.errors?.length) throw new Error(data.errors[0].message);

        commit("addPlanDiary", data.data.createPlaneDiary);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deletePlan({ commit }, { planKey }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: DELETE_PLAN,
          variables: {
            key: planKey,
          },
        });

        if (data.deletePlane) commit("deletePlan", planKey);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deletePlanDiary({ commit }, { key }) {
      try {
        await apollo.client.mutate({
          mutation: DELETE_PLAN_DIARY,
          variables: {
            key: key,
          },
        });

        commit("deletePlanDiary", key);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetPlan({ commit }) {
      commit("setPlans", null);
    },
    // eslint-disable-next-line
    async generateAutodeskAccessToken({}) {
      const { data } = await apollo.client.query({
        query: GENERATE_AUTODESK_ACCESS_TOKEN,
      });
      return data.generateAutodeskAccessToken;
    },
  },
};

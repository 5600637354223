<template>
  <Navbar v-if="getIsAuthenticated" />
  <div class="flex min-h-full overflow-x-hidden">
    <Menu v-if="getIsAuthenticated" v-show="getShowMenu" />
    <div
      class="
        w-full
        relative
        min-h-screen
        overflow-x-hidden
        transform
        transition
        duration-200
        ease-in-out
        ml-0
      "
      :class="`${getShowMenu ? 'md:ml-72 ' : ''} ${
        getIsAuthenticated ? 'mt-14' : ''
      }`"
    >
      <!-- :class="`${openSidebar ? 'ml-72' : ''}`" -->
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
// import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Navbar from "@/components/inc/Navbar";
import Menu from "@/components/inc/Menu";

// const router = useRouter();
const store = useStore();
const getIsAuthenticated = computed(() => store.getters.getIsAuthenticated);
// const getWork = computed(() => store.getters.getWork);
const getShowMenu = computed(() => store.getters.getShowMenu);

/*
const conditionShowMenu = computed(
  () =>
    getIsAuthenticated.value &&
    getWork.value &&
    router.currentRoute.value.name?.toString() !== "home"
);
*/

const currentUser = async () => {
  await store.dispatch("currentUser");
};

if (!getIsAuthenticated.value) currentUser();
</script>

<style>
@font-face {
  font-family: "GotamRounded";
  src: url("~@/assets/font/gotham-rounded/GothamRoundedLight_21020.ttf");
}
@font-face {
  font-family: "GotamRoundedBold";
  src: url("~@/assets/font/gotham-rounded/GothamRoundedBold_21016.ttf");
}
@font-face {
  font-family: "GotamRoundedLight";
  src: url("~@/assets/font/gotham-rounded/GothamRoundedLight_21020.ttf");
}
@font-face {
  font-family: "GotamRoundedMedium";
  src: url("~@/assets/font/gotham-rounded/GothamRoundedMedium_21022.ttf");
}
body {
  font-family: "GotamRounded", serif !important;
}
.font-bold {
  font-family: "GotamRoundedBold", serif !important;
}
.font-medium {
  font-family: "GotamRoundedMedium", serif !important;
}
.font-light {
  font-family: "GotamRoundedLight", serif !important;
}
.text-dark {
  color: #717191;
}
</style>

<template>
  <header>
    <nav
      class="
        flex flex-wrap
        items-center
        justify-between
        w-full
        py-4
        md:py-0
        px-4
        text-lg
        bg-tertiary
        fixed
        z-40
      "
    >
      <div class="flex">
        <button v-if="isToShow" @click="onToggleMenu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="menu-button"
            class="h-6 w-6 cursor-pointer block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        <div class="pl-2">
          <img
            src="@/assets/bildin-logo-white.svg"
            height="56"
            class="mx-auto cursor-pointer"
            @click="onGoHome(false)"
          />
        </div>
      </div>

      <div class="hidden w-full md:flex md:items-center md:w-auto" id="menu">
        <ul class="pt-4 text-base md:flex md:justify-between md:pt-0">
          <li v-if="getUserAuthenticated.type !== $roles.SUPER_ADMIN">
            <div class="relative">
              <a
                class="md:p-4 py-2 flex text-white cursor-pointer"
                @click="openWorks = !openWorks"
              >
                <span class="mr-2">
                  {{ getWork ? getWork.name : "Lista de Obras" }}
                </span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="{ 'rotate-180': openWorks, 'rotate-0': !openWorks }"
                  class="inline transition-transform duration-200 transform"
                >
                  <circle
                    opacity="0.1"
                    cx="12"
                    cy="12"
                    r="12"
                    transform="rotate(-90 12 12)"
                    fill="white"
                  />
                  <path
                    d="M16.9779 10.6277C16.9782 10.7398 16.954 10.8505 16.9069 10.9517C16.8598 11.053 16.791 11.1422 16.7057 11.2127L12.2917 14.8352C12.1601 14.9456 11.995 15.0059 11.8246 15.0059C11.6542 15.0059 11.4891 14.9456 11.3574 14.8352L6.94349 11.0852C6.79326 10.9579 6.69878 10.775 6.68085 10.5767C6.66291 10.3784 6.72299 10.1809 6.84786 10.0277C6.97273 9.87458 7.15216 9.77826 7.34669 9.75998C7.54122 9.74169 7.7349 9.80294 7.88513 9.93024L11.8283 13.2827L15.7714 10.0427C15.8794 9.95103 16.0109 9.89277 16.1503 9.87486C16.2897 9.85695 16.4313 9.88014 16.5582 9.94168C16.6851 10.0032 16.7921 10.1005 16.8665 10.2221C16.9409 10.3437 16.9795 10.4845 16.9779 10.6277Z"
                    fill="white"
                  />
                </svg>
              </a>
              <div
                v-if="openWorks"
                x-transition:enter="transition ease-out duration-100"
                x-transition:enter-start="transform opacity-0 scale-95"
                x-transition:enter-end="transform opacity-100 scale-100"
                x-transition:leave="transition ease-in duration-75"
                x-transition:leave-start="transform opacity-100 scale-100"
                x-transition:leave-end="transform opacity-0 scale-95"
                class="
                  z-50
                  absolute
                  right-0
                  w-full
                  mt-2
                  origin-top-right
                  rounded-md
                  shadow-lg
                  md:w-48
                  text-gray
                  flex flex-col
                  justify-between
                "
              >
                <div
                  class="
                    px-2
                    py-2
                    bg-white
                    rounded-t-md
                    shadow
                    dark-mode:bg-gray-800
                    overflow-y-auto
                    max-h-80
                  "
                >
                  <template v-for="(work, i) in getWorks" :key="`item-${i}`">
                    <a
                      v-if="!getWork || (getWork && getWork.key !== work.key)"
                      class="
                        block
                        px-4
                        py-2
                        mt-2
                        text-sm
                        bg-transparent
                        rounded-lg
                        dark-mode:bg-transparent
                        dark-mode:hover:bg-gray-600
                        dark-mode:focus:bg-gray-600
                        dark-mode:focus:text-white
                        dark-mode:hover:text-white
                        dark-mode:text-gray-200
                        md:mt-0
                        hover:text-gray-900
                        focus:text-gray-900
                        hover:bg-gray-200
                        focus:bg-gray-200
                        focus:outline-none
                        focus:shadow-outline
                        cursor-pointer
                      "
                      @click="onLoginWork(work.key)"
                    >
                      {{ work.name }}
                    </a>
                  </template>
                </div>
                <div
                  v-if="getUserAuthenticated.type === $roles.ADMIN"
                  class="
                    px-4
                    py-2
                    bg-white
                    dark-mode:bg-gray-800
                    dark-mode:text-gray-200
                    dark-mode:hover:bg-gray-600
                    dark-mode:focus:bg-gray-600
                    dark-mode:focus:text-primary
                    dark-mode:hover:text-primary
                    hover:bg-gray-200
                    focus:bg-gray-200
                    text-primary
                    shadow
                    font-medium
                    rounded-b-md
                    cursor-pointer
                  "
                  @click="onCreateWork"
                >
                  Crear obra
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="relative">
              <!-- <button @click="open = !open" class="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"> -->
              <a
                class="md:p-4 py-2 flex text-white cursor-pointer"
                @click="open = !open"
              >
                <span class="mr-2">{{ getUserAuthenticated.name }}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="{ 'rotate-180': open, 'rotate-0': !open }"
                  class="inline transition-transform duration-200 transform"
                >
                  <circle
                    opacity="0.1"
                    cx="12"
                    cy="12"
                    r="12"
                    transform="rotate(-90 12 12)"
                    fill="white"
                  />
                  <path
                    d="M16.9779 10.6277C16.9782 10.7398 16.954 10.8505 16.9069 10.9517C16.8598 11.053 16.791 11.1422 16.7057 11.2127L12.2917 14.8352C12.1601 14.9456 11.995 15.0059 11.8246 15.0059C11.6542 15.0059 11.4891 14.9456 11.3574 14.8352L6.94349 11.0852C6.79326 10.9579 6.69878 10.775 6.68085 10.5767C6.66291 10.3784 6.72299 10.1809 6.84786 10.0277C6.97273 9.87458 7.15216 9.77826 7.34669 9.75998C7.54122 9.74169 7.7349 9.80294 7.88513 9.93024L11.8283 13.2827L15.7714 10.0427C15.8794 9.95103 16.0109 9.89277 16.1503 9.87486C16.2897 9.85695 16.4313 9.88014 16.5582 9.94168C16.6851 10.0032 16.7921 10.1005 16.8665 10.2221C16.9409 10.3437 16.9795 10.4845 16.9779 10.6277Z"
                    fill="white"
                  />
                </svg>

                <!-- <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  :class="{ 'rotate-180': open, 'rotate-0': !open }"
                  class="
                    inline
                    w-5
                    h-5
                    mt-1
                    ml-1
                    transition-transform
                    duration-200
                    transform
                    md:-mt-1
                  "
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg> -->
              </a>
              <!-- </button> -->
              <div
                v-if="open"
                x-transition:enter="transition ease-out duration-100"
                x-transition:enter-start="transform opacity-0 scale-95"
                x-transition:enter-end="transform opacity-100 scale-100"
                x-transition:leave="transition ease-in duration-75"
                x-transition:leave-start="transform opacity-100 scale-100"
                x-transition:leave-end="transform opacity-0 scale-95"
                class="
                  z-50
                  absolute
                  right-0
                  w-full
                  mt-2
                  origin-top-right
                  rounded-md
                  shadow-lg
                  md:w-48
                  text-gray
                "
              >
                <div
                  class="
                    px-2
                    py-2
                    bg-white
                    rounded-md
                    shadow
                    dark-mode:bg-gray-800
                  "
                >
                  <a
                    class="
                      block
                      px-4
                      py-2
                      mt-2
                      text-sm
                      bg-transparent
                      rounded-lg
                      dark-mode:bg-transparent
                      dark-mode:hover:bg-gray-600
                      dark-mode:focus:bg-gray-600
                      dark-mode:focus:text-white
                      dark-mode:hover:text-white
                      dark-mode:text-gray-200
                      md:mt-0
                      hover:text-gray-900
                      focus:text-gray-900
                      hover:bg-gray-200
                      focus:bg-gray-200 focus:outline-none focus:shadow-outline
                      cursor-pointer
                    "
                    @click="onChangePassword"
                  >
                    Cambio de contraseña
                  </a>
                  <a
                    class="
                      block
                      px-4
                      py-2
                      mt-2
                      text-sm
                      bg-transparent
                      rounded-lg
                      dark-mode:bg-transparent
                      dark-mode:hover:bg-gray-600
                      dark-mode:focus:bg-gray-600
                      dark-mode:focus:text-white
                      dark-mode:hover:text-white
                      dark-mode:text-gray-200
                      md:mt-0
                      hover:text-gray-900
                      focus:text-gray-900
                      hover:bg-gray-200
                      focus:bg-gray-200 focus:outline-none focus:shadow-outline
                      cursor-pointer
                    "
                    @click="onLogout"
                    >Cerrar sesión</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li>
            <a class="md:p-4 py-2 block hover:text-purple-400" href="#">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4286 16.5692H17V10.4978C17 7.97813 15.1375 5.89598 12.7143 5.54955V4.85491C12.7143 4.46027 12.3946 4.14062 12 4.14062C11.6054 4.14062 11.2857 4.46027 11.2857 4.85491V5.54955C8.8625 5.89598 7 7.97813 7 10.4978V16.5692H6.57143C6.25536 16.5692 6 16.8246 6 17.1406V17.7121C6 17.7906 6.06429 17.8549 6.14286 17.8549H10C10 18.9585 10.8964 19.8549 12 19.8549C13.1036 19.8549 14 18.9585 14 17.8549H17.8571C17.9357 17.8549 18 17.7906 18 17.7121V17.1406C18 16.8246 17.7446 16.5692 17.4286 16.5692ZM12 18.7121C11.5268 18.7121 11.1429 18.3281 11.1429 17.8549H12.8571C12.8571 18.3281 12.4732 18.7121 12 18.7121ZM8.28571 16.5692V10.4978C8.28571 9.50491 8.67143 8.57277 9.37321 7.87098C10.075 7.1692 11.0071 6.78348 12 6.78348C12.9929 6.78348 13.925 7.1692 14.6268 7.87098C15.3286 8.57277 15.7143 9.50491 15.7143 10.4978V16.5692H8.28571Z"
                  fill="white"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const app = getCurrentInstance();
const roles = app.appContext.config.globalProperties.$roles;

const open = ref(false);
const openWorks = ref(false);

const getUserAuthenticated = computed(() => store.getters.getUserAuthenticated);
const getWorks = computed(() => store.getters.getWorks);
const getWork = computed(() => store.getters.getWork);
const getIsAuthenticated = computed(() => store.getters.getIsAuthenticated);
const isToShow = computed(
  () =>
    router.currentRoute.value.name?.toString() !== "home" &&
    getIsAuthenticated.value &&
    getWork.value
);

const onLogout = async () => {
  await store.dispatch("logout");
  router.push({ name: "login" });
  router.go(0);
};

const onLoginWork = (workKey) => {
  open.value = false;
  openWorks.value = false;
  store.dispatch("resetWork");
  store.dispatch("setWork", { key: workKey });
  router.push({ name: "welcome" });
};

const onCreateWork = () => {
  openWorks.value = false;
  onGoHome(true);
};

const onGoHome = (openShowStepper = false) => {
  store.dispatch("toggleShowStepper", {
    toggle:
      getUserAuthenticated.value.type === roles.ADMIN &&
      (openShowStepper || (getWorks.value && !getWorks.value.length)),
  });
  router.push({ name: "home" });
};

const onChangePassword = () => {
  open.value = false;
  router.push({ name: "change-password" });
};

const onToggleMenu = () => {
  store.dispatch("toggleShowMenu");
};
</script>

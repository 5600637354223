import { useApolloClient } from "../../composables/useApolloClient";
import {
  CREATE_MEMBER,
  CREATE_MEMBER_EXISTS,
  CREATE_MEMBER_USER_EXISTS,
  CREATE_WORKER,
  IMPORT_MEMBERS,
  UPDATE_MEMBER,
  UPDATE_WORKER,
  DELETE_USER,
  IMPORT_CA_WORKERS,
} from "../../graphql/mutations";
import {
  ALL_USERS,
  ALL_USERS_WITHOUT_GROUP,
  FIND_FOREMAN,
} from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    members: null,
    foremans: null,
    observableGetAllMembersWithoutGroup: null,
    membersWithoutGroup: null,
  },
  getters: {
    getMembers(state) {
      return state.members;
    },
    getForemans(state) {
      return state.foremans;
    },
    getMembersWithoutGroup(state) {
      return state.membersWithoutGroup;
    },
  },
  mutations: {
    setMembers(state, payload) {
      state.members = payload;
    },
    setForemans(state, payload) {
      state.foremans = payload;
    },
    addMember(state, payload) {
      state.members = (state.members || []).concat(payload);
    },
    updateMember(state, payload) {
      const memberInd = state.members.findIndex((m) => m.key === payload.key);
      state.members[memberInd] = payload;
    },
    deleteMember(state, payload) {
      state.members = state.members.filter((u) => u.key !== payload);
    },
    setMembersWithoutGroup(state, payload) {
      state.membersWithoutGroup = payload;
    },
    setObservableGetAllMembersWithoutGroup(state, payload) {
      state.observableGetAllMembersWithoutGroup = payload;
    },
  },
  actions: {
    async getAllMembers({ commit, rootState }, payload) {
      try {
        const filter = {
          type: ["Worker"],
          workKey: rootState.work.work.key,
        };

        if (payload) {
          if (payload.userEntity) filter.userEntity = payload.userEntity;
          if (payload.type) filter.type = payload.type;
        }

        const { data } = await apollo.client.query({
          query: ALL_USERS,
          variables: {
            filter: filter,
            workKey: filter.workKey,
            perPage: 500,
          },
        });

        commit("setMembers", JSON.parse(JSON.stringify(data.allUsers.users)));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllMembersWithoutGroup({ rootState, commit }, payload) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
        };
        if (payload?.qSearch) {
          filter.q = payload.qSearch;
        }
        if (payload?.specialityKey) {
          filter.specialityKey = payload.specialityKey;
        }

        const query = apollo.client.watchQuery({
          query: ALL_USERS_WITHOUT_GROUP,
          fetchPolicy: "network-only",
          variables: {
            filter: filter,
            workKey: filter.workKey,
            perPage: 5000,
          },
        });

        const observable = query.subscribe(({ data }) => {
          commit(
            "setMembersWithoutGroup",
            JSON.parse(JSON.stringify(data.allUsersWithoutGroup.users))
          );
        });

        commit("setObservableGetAllMembersWithoutGroup", observable);

        // if (payload?.cancel) observable.unsubscribe();

        // const { data } = await apollo.client.query({
        //   query: ALL_USERS_WITHOUT_GROUP,
        //   variables: {
        //     filter: filter,
        //     workKey: filter.workKey,
        //     perPage: 5000,
        //   },
        // });

        // return JSON.parse(JSON.stringify(data.allUsersWithoutGroup.users));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    cancelGetAllMembersWithoutGroup({ state }) {
      state.observableGetAllMembersWithoutGroup.unsubscribe();
    },
    async searchAllMembers(
      { rootState },
      { qSearch = "", specialityKey = "", userEntity = "" }
    ) {
      try {
        const workKey = rootState.work.work.key;
        const filter = { type: ["Worker"], workKey };

        if (qSearch) filter.q = qSearch;
        if (specialityKey) filter.specialityKey = specialityKey;
        if (userEntity) filter.userEntity = userEntity;

        const { data } = await apollo.client.query({
          query: ALL_USERS,
          variables: {
            filter,
            workKey,
            perPage: 500,
          },
        });

        return JSON.parse(JSON.stringify(data.allUsers.users));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createMember({ commit, rootState }, { member }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_MEMBER,
          variables: {
            input: {
              ...member,
              workKey: rootState.work.work.key,
            },
            workKey: rootState.work.work.key,
          },
        });

        commit("addMember", data.createMember);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createMemberExists({ commit, rootState }, { member }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_MEMBER_EXISTS,
          variables: {
            input: {
              ...member,
              workKey: rootState.work.work.key,
            },
            workKey: rootState.work.work.key,
          },
        });

        commit("addMember", data.createUserExists);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createMemberAdminExists({ commit, rootState }, { member }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_MEMBER_USER_EXISTS,
          variables: {
            input: {
              ...member,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("addMember", data.createUserAdminExists);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateMember({ commit, rootState }, { member }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_MEMBER,
          variables: {
            input: {
              ...member,
              workKey: rootState.work.work.key,
            },
            workKey: rootState.work.work.key,
          },
        });

        commit("updateMember", data.updateMember);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetMembers({ commit }) {
      commit("setMembers", null);
    },
    async createWorker({ commit, rootState }, { member }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_WORKER,
          variables: {
            input: {
              ...member,
              workKey: rootState.work.work.key,
            },
            workKey: rootState.work.work.key,
          },
        });

        commit("addMember", data.createWorker);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateWorker({ commit, rootState }, { member }) {
      try {
        const workKey = rootState.work.work.key;

        const { data } = await apollo.client.mutate({
          mutation: UPDATE_WORKER,
          variables: {
            input: {
              ...member,
              workKey,
            },
            workKey,
          },
        });

        commit("updateMember", data.updateWorker);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async findForemans({ commit, rootState }, { q }) {
      try {
        let foremans = [];

        if (q) {
          const { data } = await apollo.client.query({
            query: FIND_FOREMAN,
            variables: {
              workKey: rootState.work.work.key,
              q: q,
            },
          });

          foremans = JSON.parse(JSON.stringify(data.findForeman));
        }

        commit("setForemans", foremans);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async importMembers({ rootState }, { file }) {
      try {
        const body = new FormData();
        body.append(
          "operations",
          JSON.stringify({
            query: IMPORT_MEMBERS,
            variables: {
              input: {
                workKey: rootState.work.work.key,
                file: null,
              },
            },
          })
        );
        body.append("map", '{ "0": [ "variables.input.file" ]}');
        body.append("0", file);

        const response = await fetch(process.env.VUE_APP_GRAPHQL_BASE_URL, {
          body: body,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "POST",
        });

        const data = await response.json();
        if (data.errors) throw data.errors[0];

        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async importCAWorkers({ rootState }) {
      try {
        const workKey = rootState.work.work.key;

        await apollo.client.mutate({
          mutation: IMPORT_CA_WORKERS,
          variables: {
            input: {
              workKey,
            },
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteMember({ commit, rootState }, { key }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: DELETE_USER,
          variables: {
            key,
            condition: { workKey: rootState.work.work.key },
          },
        });

        if (data.deleteUser) commit("deleteMember", key);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

import { useApolloClient } from "../../composables/useApolloClient";
import { ALL_CATEGORIES, ALL_WORK_CATEGORIES } from "../../graphql/queries";
import {
  ENABLE_WORK_CATEGORIES,
  CREATE_WORK_CATEGORY,
  UPDATE_WORK_CATEGORY,
} from "../../graphql/mutations";

const apollo = useApolloClient();

export default {
  state: {
    categories: null,
    workCategories: null,
  },
  getters: {
    getCategories(state) {
      return state.categories;
    },
    getWorkCategories(state) {
      return state.workCategories;
    },
  },
  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
    },
    setWorkCategories(state, payload) {
      state.workCategories = payload;
    },
    addWorkCategory(state, payload) {
      state.workCategories = state.workCategories.concat(payload);
    },
    updateWorkCategory(state, payload) {
      const workCategoryInd = state.workCategories.findIndex(
        (c) => c.key === payload.key
      );

      if (workCategoryInd >= 0) state.workCategories[workCategoryInd] = payload;
    },
  },
  actions: {
    async getAllCategories({ commit }) {
      const { data } = await apollo.client.query({
        query: ALL_CATEGORIES,
        variables: {
          perPage: 300,
        },
      });

      commit(
        "setCategories",
        JSON.parse(JSON.stringify(data.allCategories.categories))
      );
    },
    async getAllWorkCategories({ commit, rootState }) {
      const { data } = await apollo.client.query({
        query: ALL_WORK_CATEGORIES,
        variables: {
          filter: {
            workKey: rootState.work.work.key,
          },
        },
      });

      commit(
        "setWorkCategories",
        JSON.parse(JSON.stringify(data.allWorkCategories))
      );
    },
    async enableWorkCategories({ commit, rootState }) {
      const { data } = await apollo.client.mutate({
        mutation: ENABLE_WORK_CATEGORIES,
        variables: {
          filter: {
            workKey: rootState.work.work.key,
          },
        },
      });

      commit(
        "setWorkCategories",
        JSON.parse(JSON.stringify(data.enableWorkCategories))
      );
    },
    async createWorkCategory({ commit, state }, { workCategory }) {
      const { data } = await apollo.client.mutate({
        mutation: CREATE_WORK_CATEGORY,
        variables: {
          input: workCategory,
        },
      });

      if (!state.workCategories)
        commit("setWorkCategories", data.createWorkCategory);

      commit("addWorkCategory", data.createWorkCategory);
    },
    async updateWorkCategory({ commit }, { workCategory }) {
      const { data } = await apollo.client.mutate({
        mutation: UPDATE_WORK_CATEGORY,
        variables: {
          input: workCategory,
        },
      });

      commit("updateWorkCategory", data.updateWorkCategory);
    },
  },
};

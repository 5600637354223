import { gql } from "@apollo/client/core";

export const ME = gql`
  query {
    Me {
      id
      key
      rev
      type
      currentRole {
        id
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
      }
      name
      lastName
      lastName2
      email
      phone
      dni
      company
      workConnection {
        pageInfo {
          hasPreviousPage
          hasNextPage
          currentPage
          perPage
          total
        }
        works {
          id
          key
          rev
          name
          image
          useSectors
          useFillAutomaticAdvances
          roles {
            id
            key
            rev
            name
            permissions {
              module
              hasMobilePermissions
              hasWebPermissions
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const PERMISSIONS = gql`
  query permissions($workKey: ID!) {
    Me {
      speciality(workKey: $workKey) {
        key
        name
      }
      currentRole(workKey: $workKey) {
        permissions {
          module
          hasWebPermissions
          hasMobilePermissions
        }
      }
    }
  }
`;

export const ALL_USERS = gql`
  query allUsers($filter: UserFilter, $workKey: ID!, $perPage: Int64) {
    allUsers(filter: $filter, perPage: $perPage) {
      users {
        key
        id
        dni
        type
        name
        lastName
        lastName2
        email
        company
        phone
        userEntity
        createdAt
        code
        admissionDate
        customSpeciality
        category(workKey: $workKey) {
          id
          key
          name
        }
        speciality(workKey: $workKey) {
          id
          key
          name
        }
        currentRole(workKey: $workKey) {
          key
          id
          name
          permissions {
            module
            hasWebPermissions
            hasMobilePermissions
          }
        }
        workConnection {
          works {
            name
          }
        }
      }
    }
  }
`;
export const ALL_USERS_WITHOUT_GROUP = gql`
  query allUsersWithoutGroup(
    $filter: UserFilter
    $workKey: ID!
    $perPage: Int64
  ) {
    allUsersWithoutGroup(filter: $filter, perPage: $perPage) {
      users {
        key
        id
        dni
        type
        name
        lastName
        lastName2
        email
        company
        phone
        userEntity
        customSpeciality
        createdAt
        category(workKey: $workKey) {
          id
          key
          name
        }
        speciality(workKey: $workKey) {
          id
          key
          name
        }
        currentRole(workKey: $workKey) {
          key
          id
          name
        }
      }
    }
  }
`;

export const ALL_USERS_ADMINS = gql`
  query allUsers($filter: UserFilter, $perPage: Int64) {
    allUsers(filter: $filter, perPage: $perPage) {
      users {
        key
        id
        dni
        type
        name
        lastName
        lastName2
        email
        company
        phone
        userEntity
        code
        admissionDate
      }
    }
  }
`;

export const ALL_WORK_USERS = gql`
  query allUsers($filter: UserFilter, $perPage: Int64) {
    allUsers(filter: $filter, perPage: $perPage) {
      users {
        key
        id
        dni
        type
        name
        lastName
        lastName2
        email
        company
        phone
        userEntity
        code
        admissionDate
        customSpeciality
        speciality(workKey: $workKey) {
          id
          key
          name
        }
      }
    }
  }
`;

export const FIND_USERS_BY_EMAIL = gql`
  query findUserByEmail($email: String!) {
    findUserByEmail(email: $email) {
      id
      key
      name
      lastName
      lastName2
      dni
      email
      company
      phone
      type
    }
  }
`;

export const FIND_FOREMAN = gql`
  query findForeman($workKey: ID!, $q: String) {
    findForeman(workKey: $workKey, q: $q) {
      id
      key
      rev
      name
      lastName
      lastName2
      dni
      email
      company
    }
  }
`;

export const ALL_SPECIALITIES = gql`
  query allSpecialities($filter: SpecialityFilter, $perPage: Int64) {
    allSpecialities(filter: $filter, perPage: $perPage) {
      specialities {
        id
        key
        name
      }
    }
  }
`;

export const ALL_CATEGORIES = gql`
  query allCategories($perPage: Int64) {
    allCategories(perPage: $perPage) {
      categories {
        id
        key
        name
        unitCost
      }
    }
  }
`;

export const ALL_DEPARTURES = gql`
  query allDepartures($filter: DepartureFilter, $perPage: Int64) {
    allDepartures(filter: $filter, perPage: $perPage) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        currentPage
        perPage
        total
      }
      departures {
        id
        key
        rev
        name
        code
        budget
        unit
        budgetIp
        budgetMetered
        budgetHours
        goalHours
        startDate
        endDate
        isToCalculateIp
        categoryCapatazBudget
        categoryOperarioBudget
        categoryOficialBudget
        categoryAyudanteBudget
        subdepartures {
          id
          key
          rev
          name
          code
          budget
          unit
          budgetIp
          budgetMetered
          budgetHours
          goalHours
          startDate
          endDate
          isToCalculateIp
          isSubDeparture
          parentDepartureKey
          categoryCapatazBudget
          categoryOperarioBudget
          categoryOficialBudget
          categoryAyudanteBudget
        }
      }
    }
  }
`;

export const ALL_GROUPS = gql`
  query allGroups($filter: GroupFilter, $workKey: ID!, $perPage: Int64) {
    allGroups(filter: $filter, perPage: $perPage) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        currentPage
        perPage
        total
      }
      groups {
        id
        key
        rev
        name
        createdAt
        foreman {
          key
          type
          dni
          name
          lastName
          lastName2
          email
          company
          phone
          speciality(workKey: $workKey) {
            key
            name
          }
        }
        memberConnection(perPage: $perPage) {
          pageInfo {
            hasPreviousPage
            hasNextPage
            currentPage
            perPage
            total
          }
          members {
            key
            user {
              key
              dni
              type
              name
              lastName
              lastName2
              email
              company
              phone
              category(workKey: $workKey) {
                key
                name
              }
              speciality(workKey: $workKey) {
                id
                key
                name
                createdAt
              }
              userEntity
            }
          }
        }
      }
    }
  }
`;

export const LIST_GROUPS = gql`
  query allGroups($filter: GroupFilter, $perPage: Int64) {
    allGroups(filter: $filter, perPage: $perPage) {
      groups {
        key
        name
      }
    }
  }
`;

export const GET_WEEK_KEY_BY_DATE = gql`
  query findScheduleByWeek($workKey: ID!, $weekDate: String!) {
    findScheduleByWeek(workKey: $workKey, weekDate: $weekDate) {
      id
      key
    }
  }
`;

export const FIND_SCHEDULE_BY_WEEK = gql`
  query findScheduleByWeek($workKey: ID!, $weekDate: String!) {
    findScheduleByWeek(workKey: $workKey, weekDate: $weekDate) {
      id
      key
      name
      initialDate
      ppc
      schedule(type: Weekly) {
        id
        key
        weekKey
        name
        date
        hour
        status
        color
        timeout
        isToDispatch
        sort
        unit
        metered
        departure {
          id
          key
          name
        }
        user {
          id
          key
          name
          lastName
          lastName2
          email
          dni
          company
        }
        users {
          id
          key
          name
          lastName
          lastName2
          email
          dni
          company
        }
        sector {
          key
          name
        }
        cause {
          id
          key
          cause
          types {
            id
            key
            name
          }
          subcontractor {
            key
            name
          }
        }
      }
    }
  }
`;

export const FIND_SCHEDULE_BY_DAY = gql`
  query findScheduleByDay($workKey: ID!, $date: String!) {
    findScheduleByDay(workKey: $workKey, date: $date) {
      id
      key
      weekKey
      name
      date
      hour
      status
      isToDispatch
      image
      timeout
      sort
      unit
      metered
      departure {
        id
        key
        name
      }
      user {
        id
        key
        name
        lastName
        lastName2
        email
        dni
        company
      }
      cause {
        id
        key
        types {
          id
          key
          name
        }
        subcontractor {
          key
          name
        }
        cause
      }
    }
  }
`;

export const FIND_SCHEDULE_NOT_COMPLETED = gql`
  query findScheduleNotCompleted($workKey: ID!, $weekKey: ID!) {
    findScheduleNotCompleted(workKey: $workKey, weekKey: $weekKey) {
      id
      key
      weekKey
      name
      date
      hour
      status
      timeout
      sector {
        key
        name
      }
      departure {
        id
        key
        name
      }
      user {
        id
        key
        name
        lastName
        lastName2
        dni
        email
        company
      }
      cause {
        id
        key
        scheduleKey
        types {
          id
          key
          name
        }
        subcontractor {
          key
          name
        }
        cause
        createdAt
      }
    }
  }
`;

export const ALL_TYPES = gql`
  query allTypes($filter: TypeFilter, $perPage: Int64) {
    allTypes(filter: $filter, perPage: $perPage) {
      types {
        id
        key
        name
      }
    }
  }
`;

export const ALL_RESTRICTIONS = gql`
  query allRestrictions($filter: RestrictionFilter, $perPage: Int64) {
    allRestrictions(filter: $filter, perPage: $perPage) {
      restrictions {
        key
        name
        scheduleName
        startDate
        type {
          key
          name
        }
        user {
          key
          name
          lastName
          lastName2
          dni
          email
        }
        liftingDate
        status
      }
    }
  }
`;

export const ALL_PLANS = gql`
  query allPlanes($filter: PlaneFilter, $perPage: Int64) {
    allPlanes(filter: $filter, perPage: $perPage) {
      planes {
        id
        key
        name
        workKey
        image
        convertedImage
        urn
        type
        progress
        speciality {
          id
          key
          name
        }
      }
    }
  }
`;

export const ALL_PLANS_DIARIES = gql`
  query allPlaneDiaries($filter: PlaneDiaryFilter, $perPage: Int64) {
    allPlaneDiaries(filter: $filter, perPage: $perPage) {
      planeDiaries {
        id
        key
        workKey
        specialityKey
        image
        legend {
          name
          color
        }
        date
      }
    }
  }
`;

export const ALL_WORK_HOURS = gql`
  query allWorkHours(
    $filter: WorkHourFilter!
    $departureKey: ID
    $sectorKey: ID
    $groupKey: ID
  ) {
    allWorkHours(filter: $filter) {
      id
      key
      date
      hasRelojControlAssistance
      user {
        key
        name
        lastName
        lastName2
        dni
      }
      items(
        departureKey: $departureKey
        sectorKey: $sectorKey
        groupKey: $groupKey
      ) {
        key
        workHour
        workExtraHour
        departure {
          key
          name
          parentDepartureKey
          isSubDeparture
          parentDeparture {
            key
            name
          }
        }
        sector {
          key
          name
        }
        group {
          key
          name
          foreman {
            key
            name
            lastName
            lastName2
          }
        }
        isAuthorized
      }
    }
  }
`;

export const FIND_DEPARTURE_REPORT = gql`
  query findDepartureReport($filter: ReportFilter!) {
    findDepartureReport(filter: $filter) {
      departureKey
      name
      userWorked
    }
  }
`;

export const FIND_DEPARTURE_CATEGORY_REPORT = gql`
  query findDepartureCategoryReport($filter: ReportFilter!) {
    findDepartureCategoryReport(filter: $filter) {
      departureKey
      name
      categoryKey
      categoryName
      userWorked
    }
  }
`;

export const FIND_DEPARTURE_CATEGORY_WORK_HOUR_REPORT = gql`
  query findDepartureCategoryWorkHourReport($filter: ReportFilter!) {
    findDepartureCategoryWorkHourReport(filter: $filter) {
      departureKey
      name
      categoryKey
      categoryName
      hoursWorked
    }
  }
`;

export const FIND_DEPARTURE_CATEGORY_COST_WORK_HOUR_REPORT = gql`
  query findDepartureCategoryCostWorkHourReport($filter: ReportFilter!) {
    findDepartureCategoryCostWorkHourReport(filter: $filter) {
      departureKey
      departureBudget
      name
      categoryKey
      categoryName
      hoursWorked
      unitCost
      unitCostTotal
    }
  }
`;

export const FIND_CATEGORY_REPORT = gql`
  query findCategoryReport($filter: ReportFilter!) {
    findCategoryReport(filter: $filter) {
      categoryKey
      name
      userWorked
    }
  }
`;

export const FIND_USER_WORKED_REPORT = gql`
  query findUserWorkedReport($filter: ReportFilter!) {
    findUserWorkedReport(filter: $filter) {
      userWorked
      dateFrom
    }
  }
`;

export const FIND_HOUR_WORKED_DEPARTURE_REPORT = gql`
  query findHourWorkedDepartureReport($filter: ReportFilter!) {
    findHourWorkedDepartureReport(filter: $filter) {
      departureKey
      name
      budget
      hoursWorked
    }
  }
`;

export const FIND_DEPARTURE_WORK_HOUR_REPORT = gql`
  query findDepartureWorkHourReport($filter: ReportFilter!) {
    findDepartureWorkHourReport(filter: $filter) {
      departureKey
      name
      budgetHours
      hoursWorked
    }
  }
`;

export const FIND_ACCUMULATED_REPORT = gql`
  query findAccumulatedReport($filter: AccumulatedReportFilter!) {
    findAccumulatedReport(filter: $filter) {
      departureKey
      name
      resume {
        date
        hoursWorked
      }
    }
  }
`;

export const FIND_ACCUMULATED_HOUR_WORKED_REPORT = gql`
  query findAccumulatedHourWorkedReport($filter: AccumulatedReportFilter!) {
    findAccumulatedHourWorkedReport(filter: $filter) {
      departureKey
      name
      resume {
        initialDate
        weekName
        hoursWorked
        budgetHours
        goalHours
      }
      detail {
        date
        weekName
        hoursWorked
        budgetHours
        goalHours
      }
    }
  }
`;

export const FIND_CAUSE_REPORT = gql`
  query findCauseReport($filter: CauseReportFilter!) {
    findCauseReport(filter: $filter) {
      typeKey
      name
      percentage
    }
  }
`;

export const FIND_CAUSE_BY_DEPARTURE_REPORT = gql`
  query findCauseByDepartureReport($filter: CauseByDepartureReportFilter!) {
    findCauseByDepartureReport(filter: $filter) {
      departureKey
      name
      percentage
    }
  }
`;

export const FIND_CAUSE_BY_USER_REPORT = gql`
  query findCauseByUserReport($filter: CauseByUserReportFilter!) {
    findCauseByUserReport(filter: $filter) {
      departureKey
      name
      userWorked
    }
  }
`;

export const FIND_CAUSE_SUBCONTRACTOR_REPORT = gql`
  query findCauseSubcontractorReport($filter: CauseReportFilter!) {
    findCauseSubcontractorReport(filter: $filter) {
      subcontractorKey
      name
      percentage
    }
  }
`;

export const FIND_PPC_REPORT = gql`
  query ppcReport($filter: PPCReportFilter!) {
    ppcReport(filter: $filter) {
      name
      initialDate
      ppc
      ppcAccumulated
    }
  }
`;

export const GENERATE_AUTODESK_ACCESS_TOKEN = gql`
  query generateAccessToken {
    generateAutodeskAccessToken
  }
`;

export const ALL_WORK_CATEGORIES = gql`
  query allWorkCategories($filter: WorkCategoryFilter) {
    allWorkCategories(filter: $filter) {
      key
      categoryKey
      categoryName
      unitCost
    }
  }
`;

export const FIND_TASKER_CONFIRMATION = gql`
  query findTaskerConfirmation($filter: TaskerConfirmationFilter!) {
    findTaskerConfirmation(filter: $filter) {
      status
      date
      workKey
      workName
      firstUserEmail
      secondUserEmail
      initialDate
      week
    }
  }
`;

export const FIND_TASKER_DAILY_CONFIRMATION = gql`
  query findTaskerDailyConfirmation($filter: TaskerDailyConfirmationFilter!) {
    findTaskerDailyConfirmation(filter: $filter) {
      date
      userKey
      userEmail
      isConfirmed
    }
  }
`;

export const FIND_ALL_TASKER_DAILY_CONFIRMATIONS = gql`
  query findAllTaskerDailyConfirmations(
    $filter: TaskerDailyConfirmationFilter!
  ) {
    findAllTaskerDailyConfirmations(filter: $filter) {
      date
      userKey
      userEmail
      isConfirmed
    }
  }
`;

export const FIND_TASKER_DAILY_CONFIG = gql`
  query findTaskerDailyConfig($filter: TaskerDailyConfigFilter!) {
    findTaskerDailyConfig(filter: $filter) {
      key
      date
      isClosed
    }
  }
`;

export const FIND_TASKER_WEEKLY_CONFIG = gql`
  query findTaskerWeeklyConfig($filter: TaskerWeeklyConfigFilter!) {
    findTaskerWeeklyConfig(filter: $filter) {
      key
      initialDate
      isClosed
    }
  }
`;

export const FIND_GROUP_DEPARTURES = gql`
  query findGroupDepartures($filter: GroupDepartureFilter!) {
    findGroupDepartures(filter: $filter) {
      key
      departure {
        key
        name
      }
    }
  }
`;

export const FIND_GROUP_SECTORS = gql`
  query findGroupSectors($filter: GroupSectorFilter!) {
    findGroupSectors(filter: $filter) {
      key
      sector {
        key
        name
      }
    }
  }
`;

export const ALL_SUBCONTRACTORS = gql`
  query allSubcontractors($filter: SubcontractorFilter, $perPage: Int64) {
    allSubcontractors(filter: $filter, perPage: $perPage) {
      subcontractors {
        key
        name
      }
    }
  }
`;

export const GENERATE_DEPARTURE_IP_REPORT = gql`
  query findDepartureIpReport($filter: DepartureIpReportFilter!) {
    findDepartureIpReport(filter: $filter) {
      departureKey
      date
      ip
      ipAccumulated
      advanceAccumulated
      hhWonLost
      hhWonLostToEndWork
      workHours
      advance
      departure {
        name
        unit
        budgetIp
        budgetMetered
        budgetHours
      }
    }
  }
`;

export const GENERATE_DEPARTURE_IP_RESUME = gql`
  query findDeparturesIpResume($filter: DepartureIpResumeFilter!) {
    findDeparturesIpResume(filter: $filter) {
      resume {
        departureKey
        date
        ip
        ipAccumulated
        advanceAccumulated
        hhWonLost
        hhWonLostToEndWork
        workHoursAccumulated
        departure {
          code
          name
          unit
          budgetIp
          budgetMetered
          budgetHours
          isSubDeparture
          parentDeparture {
            key
            code
            name
            isToCalculateIp
          }
        }
      }
      detail {
        date
        hhWonLost
      }
    }
  }
`;

export const GENERATE_DEPARTURE_IP_RESUME_BY_STAGES = gql`
  query findDeparturesIpResumeByStages($filter: DepartureIpResumeFilter!) {
    findDeparturesIpResumeByStages(filter: $filter) {
      resume {
        departureKey
        stage
        date
        ip
        ipAccumulated
        advanceAccumulated
        hhWonLost
        hhWonLostToEndWork
        workHoursAccumulated
        workHours
        departure {
          code
          name
          unit
          budgetIp
          budgetMetered
          budgetHours
          isSubDeparture
          parentDeparture {
            key
            code
            name
            isToCalculateIp
          }
        }
      }
      detail {
        date
        hhWonLost
      }
      departuresName
      stagesName
    }
  }
`;

export const ALL_DEPARTURES_IP = gql`
  query allDeparturesIp($filter: DepartureIpFilter!, $perPage: Int64) {
    allDeparturesIp(filter: $filter, perPage: $perPage) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        currentPage
        perPage
        total
      }
      departuresIp {
        key
        departureKey
        sectorKey
        date
        ip
        advance
        departure {
          code
          name
          unit
          budgetIp
          budgetMetered
        }
        sector {
          key
          code
          name
          stage
        }
      }
      departuresName
      stagesName
      totalDepartures
      totalSectors
    }
  }
`;

export const ALL_SECTORS = gql`
  query allSectors($filter: SectorFilter, $perPage: Int64) {
    allSectors(filter: $filter, perPage: $perPage) {
      sectors {
        key
        name
        code
        stage
        sectorDepartureAdvances {
          key
          departureKey
          advance
          departure {
            key
            name
          }
        }
      }
    }
  }
`;

export const ALL_SECTOR_DEPARTURE_ADVANCES = gql`
  query allSectorDepartureAdvances($filter: SectorFilter) {
    allSectorDepartureAdvances(filter: $filter) {
      key
      departureKey
      sectorKey
      advance
      departure {
        key
        name
      }
    }
  }
`;

export const ALL_JORNAL = gql`
  query allJornal($filter: JornalFilter!) {
    allJornal(filter: $filter) {
      key
      name
      weekday
      hours
    }
  }
`;

export const ALL_WEEK_DEPARTURES = gql`
  query allWeekDepartures($filter: WeekDepartureFilter!) {
    allWeekDepartures(filter: $filter) {
      key
      departureKey
      userKey
      initialDate
      sort
    }
  }
`;

export const ALL_MARKED_SCHEDULES = gql`
  query allMarkedSchedules($filter: MarkedScheduleFilter!, $perPage: Int64) {
    allMarkedSchedules(filter: $filter, perPage: $perPage) {
      markedSchedules {
        key
        weekKey
        name
        date
        hour
        status
        color
        timeout
        isToDispatch
        sort
        metered
        unit
        markedAt
        scheduleKey
        departure {
          key
          name
        }
        user {
          key
          name
          lastName
          lastName2
          email
          dni
          company
        }
        users {
          key
          name
          lastName
          lastName2
          email
          dni
          company
        }
        sector {
          key
          name
        }
      }
    }
  }
`;

import {
  GENERATE_TASKER_HISTOGRAM_REPORT,
  GENERATE_TASKER_HISTORY_REPORT,
  GENERATE_PSV_WEEKLY_REPORT,
  GENERATE_TASKER_WEEKLY_REPORT,
  GENERATE_TASKER_WEEKLY_FORMAT_OPERA_REPORT,
  GENERATE_TASKER_WEEKLY_FORMAT_S10V2_REPORT,
  GENERATE_WORSHOURS_REPORT,
  GENERATE_TASKER_TEMPLATE_DAILY_REPORT,
  GENERATE_TASKER_TEMPLATE_WEEKLY_REPORT,
  GENERATE_TASKER_WEEKLY,
  GENERATE_TASKER_ACCUMULATIVE,
} from "@/graphql/mutations";
import {
  FIND_ACCUMULATED_REPORT,
  FIND_ACCUMULATED_HOUR_WORKED_REPORT,
  FIND_CATEGORY_REPORT,
  FIND_CAUSE_REPORT,
  FIND_CAUSE_BY_DEPARTURE_REPORT,
  FIND_CAUSE_BY_USER_REPORT,
  FIND_CAUSE_SUBCONTRACTOR_REPORT,
  FIND_DEPARTURE_CATEGORY_COST_WORK_HOUR_REPORT,
  FIND_DEPARTURE_CATEGORY_REPORT,
  FIND_DEPARTURE_CATEGORY_WORK_HOUR_REPORT,
  FIND_DEPARTURE_REPORT,
  FIND_HOUR_WORKED_DEPARTURE_REPORT,
  FIND_PPC_REPORT,
  FIND_USER_WORKED_REPORT,
  FIND_DEPARTURE_WORK_HOUR_REPORT,
} from "@/graphql/queries";
import { useApolloClient } from "../../composables/useApolloClient";

const apollo = useApolloClient();

export default {
  state: {
    departureReport: null,
    departureCategoryReport: null,
    departureCategoryWorkHourReport: null,
    departureCategoryCostWorkHourReport: null,
    categoryReport: null,
    hourWorkedDepartureReport: null,
    accumulatedReport: null,
    accumulatedHourWorkedReport: null,
    planningWeeklyReport: null,
    planningCauseByDepartureReport: null,
    planningCauseByUserReport: null,
    planningWeeklyCauseSubcontractReport: null,
    planningPPCReport: null,
    chartColors: [
      "#38ACFF",
      "#003D91",
      "#021F59",
      "#0057FF",
      "#00F7A0",
      "#FF6C00",
      "#FFD401",
      "#22DCE0",
      "#8A00E0",
      "#1CA09C",
      "#E00F57",
      "#00E016",
      "#FF6F5C",
    ],
  },
  getters: {
    getDepartureReport(state) {
      return state.departureReport;
    },
    getCategoryReport(state) {
      return state.categoryReport;
    },
    getHourWorkedDepartureReport(state) {
      return state.hourWorkedDepartureReport;
    },
    getDepartureWorkHourReport(state) {
      return state.departureWorkHourReport;
    },
    getAccumulatedReport(state) {
      return state.accumulatedReport;
    },
    getAccumulatedHourWorkedReport(state) {
      return state.accumulatedHourWorkedReport;
    },
    getPlanningWeeklyReport(state) {
      return state.planningWeeklyReport;
    },
    getPlanningCauseByDepartureReport(state) {
      return state.planningCauseByDepartureReport;
    },
    getPlanningCauseByUserReport(state) {
      return state.planningCauseByUserReport;
    },
    getPlanningWeeklyCauseSubcontractReport(state) {
      return state.planningWeeklyCauseSubcontractReport;
    },
    getPlanningPPCReport(state) {
      return state.planningPPCReport;
    },
    getChartColors(state) {
      return state.chartColors;
    },
    getDepartureCategoryReport(state) {
      return state.departureCategoryReport;
    },
    getDepartureCategoryWorkHourReport(state) {
      return state.departureCategoryWorkHourReport;
    },
    getDepartureCategoryCostWorkHourReport(state) {
      return state.departureCategoryCostWorkHourReport;
    },
  },
  mutations: {
    setDepartureReport(state, payload) {
      state.departureReport = payload;
    },
    setDepartureCategoryReport(state, payload) {
      state.departureCategoryReport = payload;
    },
    setDepartureCategoryWorkHourReport(state, payload) {
      state.departureCategoryWorkHourReport = payload;
    },
    setDepartureCategoryCostWorkHourReport(state, payload) {
      state.departureCategoryCostWorkHourReport = payload;
    },
    setCategoryReport(state, payload) {
      state.categoryReport = payload;
    },
    setHourWorkedDepartureReport(state, payload) {
      state.hourWorkedDepartureReport = payload;
    },
    setDepartureWorkHourReport(state, payload) {
      state.departureWorkHourReport = payload;
    },
    setAccumulatedReport(state, payload) {
      state.accumulatedReport = payload;
    },
    setAccumulatedHourWorkedReport(state, payload) {
      state.accumulatedHourWorkedReport = payload;
    },
    setPlanningWeeklyReport(state, payload) {
      state.planningWeeklyReport = payload;
    },
    setPlanningCauseByDepartureReport(state, payload) {
      state.planningCauseByDepartureReport = payload;
    },
    setPlanningCauseByUserReport(state, payload) {
      state.planningCauseByUserReport = payload;
    },
    setPlanningWeeklyCauseSubcontractorReport(state, payload) {
      state.planningWeeklyCauseSubcontractReport = payload;
    },
    setPlanningPPCReport(state, payload) {
      state.planningPPCReport = payload;
    },
  },
  actions: {
    async loadWeekReport({ commit, rootState }, { dateFrom, dateTo }) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
        };
        if (dateTo) {
          filter.dateTo = dateTo;
        }

        const { data } = await apollo.client.query({
          query: FIND_DEPARTURE_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit("setDepartureReport", data.findDepartureReport);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadDepartureCategoryReport(
      { commit, rootState },
      { dateFrom, dateTo, departuresKey }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
          departuresKey: departuresKey,
        };
        if (dateTo) {
          filter.dateTo = dateTo;
        }

        const { data } = await apollo.client.query({
          query: FIND_DEPARTURE_CATEGORY_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit("setDepartureCategoryReport", data.findDepartureCategoryReport);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadDepartureCategoryWorkHourReport(
      { commit, rootState },
      { dateFrom, dateTo, departuresKey }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
          departuresKey: departuresKey,
        };
        if (dateTo) filter.dateTo = dateTo;

        const { data } = await apollo.client.query({
          query: FIND_DEPARTURE_CATEGORY_WORK_HOUR_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setDepartureCategoryWorkHourReport",
          data.findDepartureCategoryWorkHourReport
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadDepartureCategoryCostWorkHourReport(
      { commit, rootState },
      { dateFrom, dateTo, departuresKey }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
          departuresKey: departuresKey,
        };
        if (dateTo) filter.dateTo = dateTo;

        const { data } = await apollo.client.query({
          query: FIND_DEPARTURE_CATEGORY_COST_WORK_HOUR_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setDepartureCategoryCostWorkHourReport",
          data.findDepartureCategoryCostWorkHourReport
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadDepartureWorkHourReport(
      { commit, rootState },
      { dateFrom, dateTo, departuresKey }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
        };
        if (dateTo) filter.dateTo = dateTo;
        if (departuresKey) filter.departuresKey = departuresKey;

        const { data } = await apollo.client.query({
          query: FIND_DEPARTURE_WORK_HOUR_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit("setDepartureWorkHourReport", data.findDepartureWorkHourReport);
        return data.findDepartureWorkHourReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadCategoryReport({ commit, rootState }, payload) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: payload.dateFrom,
        };
        if (payload.dateTo) {
          filter.dateTo = payload.dateTo;
        }

        const { data } = await apollo.client.query({
          query: FIND_CATEGORY_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit("setCategoryReport", data.findCategoryReport);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadUserWorkedReport({ rootState }, payload) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: payload.dateFrom,
        };
        if (payload.dateTo) filter.dateTo = payload.dateTo;
        if (payload.isLastDate) filter.isLastDate = payload.isLastDate;

        const { data } = await apollo.client.query({
          query: FIND_USER_WORKED_REPORT,
          variables: {
            filter: filter,
          },
        });

        return data.findUserWorkedReport ? data.findUserWorkedReport[0] : null;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerWeeklyReport({ rootState }, { dateFrom, dateTo }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_WEEKLY_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerWeeklyFormatOperaReport(
      { rootState },
      { dateFrom, dateTo }
    ) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_WEEKLY_FORMAT_OPERA_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateFormatOperaReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerWeeklyFormatS10v2Report(
      { rootState },
      { dateFrom, dateTo }
    ) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_WEEKLY_FORMAT_S10V2_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateFormatS10v2Report;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportAllWorkHoursAndCategoryCost(
      { rootState },
      { dateFrom, dateTo }
    ) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_WORSHOURS_REPORT,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.findAllWorkHoursAndCategoryCost;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerTemplateDaily({ rootState }, { dateFrom }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_TEMPLATE_DAILY_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateFrom,
            },
          },
        });

        return data.generateTaskerTemplateDailyReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerTemplateWeekly({ rootState }, { dateFrom, dateTo }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_TEMPLATE_WEEKLY_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateTaskerTemplateWeeklyReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerHistoryReport({ rootState }, { dateFrom, dateTo }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_HISTORY_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateHistoryReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportPSVWeeklyReport({ rootState }, { dateFrom, dateTo }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_PSV_WEEKLY_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generatePSVWeeklyReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerHistogramReport({ rootState }, { dateFrom, dateTo }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_HISTOGRAM_REPORT,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
        });

        return data.generateTaskerHistogramReport;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerWeekly({ rootState }, { dateFrom }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_WEEKLY,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: dateFrom,
              dateTo: dateFrom,
            },
          },
        });

        return data.generateTaskerWeekly;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async exportTaskerAccumulative({ rootState }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: GENERATE_TASKER_ACCUMULATIVE,
          variables: {
            input: {
              workKey: rootState.work.work.key,
            },
          },
        });

        return data.generateTaskerAccumulative;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadHourWorkedDepartureReport(
      { commit, rootState },
      { dateFrom, dateTo }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
          dateTo: dateTo,
        };

        const { data } = await apollo.client.query({
          query: FIND_HOUR_WORKED_DEPARTURE_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setHourWorkedDepartureReport",
          JSON.parse(JSON.stringify(data.findHourWorkedDepartureReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadAccumulatedReport(
      { commit, rootState },
      { dateFrom, dateTo, departuresKey }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
          dateTo: dateTo,
          departuresKey: departuresKey,
        };

        const { data } = await apollo.client.query({
          query: FIND_ACCUMULATED_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setAccumulatedReport",
          JSON.parse(JSON.stringify(data.findAccumulatedReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadAccumulatedHourWorkedReport(
      { commit, rootState },
      { dateFrom, dateTo, departuresKey }
    ) {
      try {
        const filter = {
          workKey: rootState.work.work.key,
          dateFrom: dateFrom,
          dateTo: dateTo,
          departuresKey: departuresKey,
        };

        const { data } = await apollo.client.query({
          query: FIND_ACCUMULATED_HOUR_WORKED_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setAccumulatedHourWorkedReport",
          JSON.parse(JSON.stringify(data.findAccumulatedHourWorkedReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetWeekReport({ commit }) {
      commit("setDepartureReport", null);
    },
    resetDailyReport({ commit }) {
      commit("setCategoryReport", null);
    },
    resetHourWorkedDepartureReport({ commit }) {
      commit("setHourWorkedDepartureReport", null);
    },
    resetAccumutaledReport({ commit }) {
      commit("setAccumulatedReport", null);
    },
    resetAccumutaledHourWorkedReport({ commit }) {
      commit("setAccumulatedHourWorkedReport", null);
    },
    async loadPlanningWeeklyReport(
      { commit, rootState },
      { weekDateFrom, weekDateTo }
    ) {
      try {
        let filter = {
          workKey: rootState.work.work.key,
          weekDate: weekDateFrom,
        };
        if (weekDateTo) filter.weekDateTo = weekDateTo;

        const { data } = await apollo.client.query({
          query: FIND_CAUSE_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setPlanningWeeklyReport",
          JSON.parse(JSON.stringify(data.findCauseReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadPlanningCauseByDepartureReport(
      { commit, rootState },
      { weekDateFrom, weekDateTo }
    ) {
      try {
        let filter = {
          workKey: rootState.work.work.key,
          weekDate: weekDateFrom,
        };
        if (weekDateTo) filter.weekDateTo = weekDateTo;

        const { data } = await apollo.client.query({
          query: FIND_CAUSE_BY_DEPARTURE_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setPlanningCauseByDepartureReport",
          JSON.parse(JSON.stringify(data.findCauseByDepartureReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadPlanningCauseByUserReport(
      { commit, rootState },
      { weekDateFrom, weekDateTo }
    ) {
      try {
        let filter = {
          workKey: rootState.work.work.key,
          weekDate: weekDateFrom,
        };
        if (weekDateTo) filter.weekDateTo = weekDateTo;

        const { data } = await apollo.client.query({
          query: FIND_CAUSE_BY_USER_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setPlanningCauseByUserReport",
          JSON.parse(JSON.stringify(data.findCauseByUserReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async loadPlanningWeeklyCauseSubcontractReport(
      { commit, rootState },
      { weekDateFrom, weekDateTo }
    ) {
      try {
        let filter = {
          workKey: rootState.work.work.key,
          weekDate: weekDateFrom,
        };
        if (weekDateTo) filter.weekDateTo = weekDateTo;

        const { data } = await apollo.client.query({
          query: FIND_CAUSE_SUBCONTRACTOR_REPORT,
          variables: {
            filter: filter,
          },
        });

        commit(
          "setPlanningWeeklyCauseSubcontractorReport",
          JSON.parse(JSON.stringify(data.findCauseSubcontractorReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetPlanningWeeklyReport({ commit }) {
      commit("setPlanningWeeklyReport", null);
    },
    resetPlanningCauseByDepartureReport({ commit }) {
      commit("setPlanningCauseByDepartureReport", null);
    },
    resetPlanningCauseByUserReport({ commit }) {
      commit("setPlanningCauseByUserReport", null);
    },
    resetPlanningWeeklyCauseSubcontractorReport({ commit }) {
      commit("setPlanningWeeklyCauseSubcontractorReport", null);
    },
    async loadPlanningPPCReport(
      { commit, rootState },
      { weekDateFrom, weekDateTo }
    ) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_PPC_REPORT,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
              weekDateFrom: weekDateFrom,
              weekDateTo: weekDateTo,
            },
          },
        });

        commit(
          "setPlanningPPCReport",
          JSON.parse(JSON.stringify(data.ppcReport))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetPlanningPPCReport({ commit }) {
      commit("setPlanningPPCReport", null);
    },
  },
};

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="onClose()">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
          />
        </TransitionChild>
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="relative w-screen max-w-xs mt-14">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="
                    absolute
                    top-0
                    left-0
                    -ml-8
                    pt-4
                    pr-2
                    flex
                    sm:-ml-10 sm:pr-4
                  "
                >
                  <button
                    type="button"
                    class="
                      rounded-md
                      text-gray-300
                      hover:text-white
                      focus:outline-none focus:ring-2 focus:ring-white
                    "
                    @click="onClose()"
                  >
                    <span class="sr-only">Close panel</span>
                    <!-- <XIcon class="h-6 w-6" aria-hidden="true" /> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M13.4577 4.54025L12.4589 3.5415L8.49935 7.50109L4.53977 3.5415L3.54102 4.54025L7.5006 8.49984L3.54102 12.4594L4.53977 13.4582L8.49935 9.49859L12.4589 13.4582L13.4577 12.4594L9.4981 8.49984L13.4577 4.54025Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </button>
                </div>
              </TransitionChild>
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <!-- <div class="px-4 sm:px-6">
                  <DialogTitle class="text-lg font-medium text-secondary">
                    {{ title }}
                  </DialogTitle>
                </div> -->
                <div class="relative flex-1 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 px-4 sm:px-6">
                    <slot></slot>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// import { ref } from "vue";

import {
  Dialog,
  DialogOverlay,
  //   DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { defineProps, defineEmits } from "vue";

const emit = defineEmits(["update:open"]);

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
});

const onClose = () => {
  emit("update:open", false);
};
</script>

import { useApolloClient } from "../../composables/useApolloClient";
import dayjs from "dayjs";
import {
  CREATE_SCHEDULE,
  UPDATE_SCHEDULE,
  DELETE_SCHEDULE,
  CREATE_CAUSE,
  UPDATE_CAUSE,
  CALCULATE_PPC,
  CALCULATE_PPC_BY_DEPARTURES,
  GENERATE_PLANNIG_SCHEDULES_REPORT,
  GENERATE_WEEKLY_PLANNING_REPORT,
  IMPORT_SCHEDULES,
  CREATE_WEEK_DEPARTURE,
  UPDATE_WEEK_DEPARTURE,
  DELETE_WEEK_DEPARTURE,
  GENERATE_WEEK_DEPARTURES,
  CREATE_MARKED_SCHEDULE,
  DELETE_MARKED_SCHEDULE,
} from "../../graphql/mutations";
import {
  FIND_SCHEDULE_BY_WEEK,
  FIND_SCHEDULE_BY_DAY,
  GET_WEEK_KEY_BY_DATE,
  ALL_WEEK_DEPARTURES,
  FIND_SCHEDULE_NOT_COMPLETED,
  ALL_MARKED_SCHEDULES,
} from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    weekInitialDate: null,
    schedule: null,
    draftSchedule: null,
    scheduleNotCompleted: null,
    schedules: null,
    schedulesCloned: null,
    daySchedule: null,
    daySchedules: null,
    weekDepartures: null,
    draftWeekDepartures: null,
    markedSchedules: null,
  },
  getters: {
    getWeekInitialDate(state) {
      return state.weekInitialDate;
    },
    getSchedule(state) {
      return state.schedule;
    },
    getDraftSchedule(state) {
      return state.draftSchedule;
    },
    getSchedules(state) {
      return state.schedules;
    },
    getSchedulesCloned(state) {
      return state.schedulesCloned;
    },
    getScheduleNotCompleted(state) {
      return state.scheduleNotCompleted;
    },
    getDaySchedule(state) {
      return state.daySchedule;
    },
    getWeekDepartures(state) {
      return state.weekDepartures;
    },
    getDraftWeekDepartures(state) {
      return state.draftWeekDepartures;
    },
    getMarkedSchedules(state) {
      return state.markedSchedules;
    },
  },
  mutations: {
    setWeekInitialDate(state, payload) {
      state.weekInitialDate = payload;
    },
    setSchedule(state, payload) {
      state.schedule = payload;
    },
    setDraftSchedule(state, payload) {
      state.draftSchedule = payload;
    },
    setScheduleNotCompleted(state, payload) {
      state.scheduleNotCompleted = payload;
    },
    setSchedules(state, payload) {
      state.schedules = payload;
    },
    addSchedule(state, payload) {
      state.schedule.schedule = state.schedule.schedule.concat(payload);
    },
    updateSchedule(state, payload) {
      const scheduleInd = state.schedule.schedule?.findIndex(
        (m) => m.key === payload.key
      );
      if (scheduleInd >= 0)
        state.schedule.schedule[scheduleInd] = {
          ...state.schedule.schedule[scheduleInd],
          ...payload,
        };
    },
    updateDraftSchedule(state, payload) {
      const scheduleInd = state.draftSchedule.schedule?.findIndex(
        (m) => m.key === payload.key
      );
      if (scheduleInd >= 0)
        state.draftSchedule.schedule[scheduleInd] = {
          ...state.draftSchedule.schedule[scheduleInd],
          ...payload,
        };
    },
    deleteSchedule(state, payload) {
      if (state.schedule)
        state.schedule.schedule = state.schedule.schedule?.filter(
          (r) => r.key !== payload
        );

      if (state.daySchedule)
        state.daySchedule = state.daySchedule?.filter((r) => r.key !== payload);
    },
    setSchedulesCloned(state, payload) {
      state.schedulesCloned = payload;
    },
    addScheduleCloned(state, payload) {
      state.schedulesCloned = state.schedulesCloned.concat(payload);
    },
    setMarkedSchedules(state, payload) {
      state.markedSchedules = payload;
    },
    addMarkedSchedule(state, payload) {
      if (!state.markedSchedules) state.markedSchedules = [];

      state.markedSchedules.unshift(payload);
    },
    deleteMarkedSchedule(state, payload) {
      if (state.markedSchedules)
        state.markedSchedules = state.markedSchedules?.filter(
          (r) => r.key !== payload
        );
    },
    updateScheduleCause(state, payload) {
      const scheduleInd = state.schedule.schedule?.findIndex(
        (m) => m.key === payload.scheduleKey
      );
      if (scheduleInd >= 0)
        state.schedule.schedule[scheduleInd] = {
          ...state.schedule.schedule[scheduleInd],
          cause: payload,
        };
    },
    setDaySchedule(state, payload) {
      state.daySchedule = payload;
    },
    addDaySchedule(state, payload) {
      state.daySchedule = state.daySchedule.concat(payload);
    },
    updateDaySchedule(state, payload) {
      const dayScheduleInd = state.daySchedule?.findIndex(
        (m) => m.key === payload.key
      );
      if (dayScheduleInd >= 0) state.daySchedule[dayScheduleInd] = payload;
    },
    setWeekDepartures(state, payload) {
      state.weekDepartures = payload;
    },
    addWeekDeparture(state, payload) {
      state.weekDepartures = state.weekDepartures.concat(payload);
    },
    updateWeekDeparture(state, payload) {
      const weekDepartureInd = state.weekDepartures?.findIndex(
        (m) => m.key === payload.key
      );
      if (weekDepartureInd >= 0)
        state.weekDepartures[weekDepartureInd] = { ...payload };
    },
    deleteWeekDeparture(state, payload) {
      if (state.weekDepartures)
        state.weekDepartures = state.weekDepartures?.filter(
          (r) => r.key !== payload
        );
    },
    setDraftWeekDepartures(state, payload) {
      state.draftWeekDepartures = payload;
    },
    updateDraftWeekDeparture(state, payload) {
      const weekDepartureInd = state.draftWeekDepartures?.findIndex(
        (m) => m.key === payload.key
      );
      if (weekDepartureInd >= 0)
        state.draftWeekDepartures[weekDepartureInd] = {
          ...state.draftWeekDepartures[weekDepartureInd],
          ...payload,
        };
    },
  },
  actions: {
    setCurrentWeekInitialDate({ commit }) {
      const today = new Date();
      today.setDate(today.getDate() - (today.getDay() - 1));
      commit("setWeekInitialDate", dayjs(today).format("YYYY-MM-DD"));
    },
    async getWeekKeyByDate({ rootState }, { date }) {
      try {
        const today = new Date(date);
        today.setDate(today.getDate() - (today.getDay() - 1));

        const monday = dayjs(today).format("YYYY-MM-DD");

        const { data } = await apollo.client.query({
          query: GET_WEEK_KEY_BY_DATE,
          variables: {
            workKey: rootState.work.work.key,
            weekDate: monday,
          },
        });

        return data.findScheduleByWeek?.key;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async findScheduleByWeek({ commit, rootState }, { weekDate, isDraft }) {
      try {
        commit(isDraft ? "setDraftSchedule" : "setSchedule", null);

        const { data } = await apollo.client.query({
          query: FIND_SCHEDULE_BY_WEEK,
          variables: {
            workKey: rootState.work.work.key,
            weekDate: weekDate,
          },
        });

        let schedules = JSON.parse(
          JSON.stringify(data.findScheduleByWeek.schedule)
        );
        schedules = schedules.sort((a, b) => {
          if (b.isToDispatch) {
            return 1;
          } else if (a.isToDispatch) {
            return -1;
          } else {
            return 0;
          }
        });

        commit(isDraft ? "setDraftSchedule" : "setSchedule", {
          ...data.findScheduleByWeek,
          schedule: schedules,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async findScheduleByDay({ commit, rootState }, { date }) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_SCHEDULE_BY_DAY,
          variables: {
            workKey: rootState.work.work.key,
            date: date,
          },
        });

        let schedules = JSON.parse(JSON.stringify(data.findScheduleByDay));
        schedules = schedules.sort((a, b) => {
          if (b.isToDispatch) {
            return 1;
          } else if (a.isToDispatch) {
            return -1;
          } else {
            return 0;
          }
        });

        commit("setDaySchedule", schedules);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createSchedule(
      { commit, rootState, state },
      { schedule, isClone = false }
    ) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_SCHEDULE,
          variables: {
            input: {
              ...schedule,
              workKey: rootState.work.work.key,
            },
          },
        });

        if (state.daySchedule) commit("addDaySchedule", data.createSchedule);

        if (
          state.schedule &&
          state.schedule.key === data.createSchedule?.weekKey
        )
          commit("addSchedule", data.createSchedule);

        if (schedule.isClone || isClone) {
          if (!state.schedulesCloned) commit("setSchedulesCloned", []);

          commit("addScheduleCloned", data.createSchedule);
        }

        return data.createSchedule;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateSchedule({ commit, rootState }, { schedule }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_SCHEDULE,
          variables: {
            input: {
              ...schedule,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("updateDaySchedule", data.updateSchedule);
        commit("updateSchedule", data.updateSchedule);

        return data.updateSchedule;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllMarkedSchedules({ commit, rootState }) {
      try {
        const { data } = await apollo.client.query({
          query: ALL_MARKED_SCHEDULES,
          variables: {
            filter: {
              workKey: rootState.work.work.key,
            },
            perPage: 1000,
          },
        });

        let markedSchedules = JSON.parse(
          JSON.stringify(data.allMarkedSchedules.markedSchedules)
        );
        markedSchedules = markedSchedules.sort((a, b) => {
          if (b.isToDispatch) {
            return 1;
          } else if (a.isToDispatch) {
            return -1;
          } else {
            return 0;
          }
        });

        commit("setMarkedSchedules", markedSchedules);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createMarkedSchedule({ commit }, { scheduleKey }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_MARKED_SCHEDULE,
          variables: {
            input: { scheduleKey },
          },
        });

        commit("deleteSchedule", scheduleKey);
        commit("addMarkedSchedule", data.createMarkedSchedule);

        return data.createMarkedSchedule;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteMarkedSchedule({ commit, state }, { key, initialDate }) {
      try {
        const markedSchedule = state.markedSchedules.find((m) => m.key === key);

        await apollo.client.mutate({
          mutation: DELETE_MARKED_SCHEDULE,
          variables: { key },
        });

        commit("deleteMarkedSchedule", key);

        if (markedSchedule.date === initialDate)
          commit("addSchedule", {
            ...markedSchedule,
            isUnmarked: true,
            key: markedSchedule.scheduleKey,
          });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateDraftSchedule({ commit }, { schedule }) {
      commit("updateDraftSchedule", { ...schedule });
    },
    async deleteSchedule({ commit }, { key }) {
      await apollo.client.mutate({
        mutation: DELETE_SCHEDULE,
        variables: {
          key: key,
        },
      });

      commit("deleteSchedule", key);
    },
    async importSchedules({ rootState }, { file, deleteExisting, date }) {
      try {
        const body = new FormData();
        body.append(
          "operations",
          JSON.stringify({
            query: IMPORT_SCHEDULES,
            variables: {
              input: {
                workKey: rootState.work.work.key,
                deleteExisting: deleteExisting,
                file: null,
                dateFrom: date,
              },
            },
          })
        );
        body.append("map", '{ "0": [ "variables.input.file" ]}');
        body.append("0", file);

        const response = await fetch(process.env.VUE_APP_GRAPHQL_BASE_URL, {
          body: body,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "POST",
        });

        const data = await response.json();
        if (data.errors) throw data.errors[0];

        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async saveScheduleCause({ commit }, { cause }) {
      try {
        const input = { scheduleKey: cause.scheduleKey };

        if (cause.typeKey) input.typeKey = cause.typeKey;
        if (cause.subcontractorKey)
          input.subcontractorKey = cause.subcontractorKey;
        if (cause.cause) input.cause = cause.cause;

        const { data } = await apollo.client.mutate({
          mutation: CREATE_CAUSE,
          variables: {
            input,
          },
        });

        commit("updateScheduleCause", data.createCause);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateScheduleCause({ commit }, { cause }) {
      try {
        const input = {
          key: cause.key,
          scheduleKey: cause.scheduleKey,
        };

        if (cause.typeKey) input.typeKey = cause.typeKey;
        if (cause.subcontractorKey)
          input.subcontractorKey = cause.subcontractorKey;
        if (cause.cause) input.cause = cause.cause;

        const { data } = await apollo.client.mutate({
          mutation: UPDATE_CAUSE,
          variables: {
            input,
          },
        });

        commit("updateScheduleCause", data.updateCause);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async findScheduleNotCompleted({ commit, rootState }, { weekKey }) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_SCHEDULE_NOT_COMPLETED,
          variables: {
            workKey: rootState.work.work.key,
            weekKey: weekKey,
          },
        });

        commit(
          "setScheduleNotCompleted",
          JSON.parse(JSON.stringify(data.findScheduleNotCompleted))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async calculatePPC({ commit }, { scheduleKey, weekKey, status }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CALCULATE_PPC,
          variables: {
            input: {
              status: status,
              scheduleKey: scheduleKey,
              weekKey: weekKey,
            },
          },
        });

        commit("setSchedule", {
          ...data.calculatePPC,
          schedule: JSON.parse(JSON.stringify(data.calculatePPC.schedule)),
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async calculatePPCByDepartures(
      { commit },
      { scheduleKey, weekKey, status }
    ) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CALCULATE_PPC_BY_DEPARTURES,
          variables: {
            input: {
              status: status,
              scheduleKey: scheduleKey,
              weekKey: weekKey,
            },
          },
        });

        commit("setSchedule", {
          ...data.calculatePPCByDepartures,
          schedule: JSON.parse(
            JSON.stringify(data.calculatePPCByDepartures.schedule)
          ),
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async generatePlanningWeeklySchedulesReport(
      { rootState },
      { dateFrom, dateTo }
    ) {
      let input = {
        workKey: rootState.work.work.key,
        dateFrom: dateFrom,
      };

      if (dateTo) input.dateTo = dateTo;

      const { data } = await apollo.client.mutate({
        mutation: GENERATE_PLANNIG_SCHEDULES_REPORT,
        variables: {
          input: input,
        },
      });

      return data.generatePlannigSchedulesReport;
    },
    async generateWeeklyPlanningReport({ rootState }, { dateFrom, dateTo }) {
      let input = {
        workKey: rootState.work.work.key,
        dateFrom: dateFrom,
      };

      if (dateTo) input.dateTo = dateTo;

      const { data } = await apollo.client.mutate({
        mutation: GENERATE_WEEKLY_PLANNING_REPORT,
        variables: {
          input: input,
        },
      });

      return data.generateWeeklyPlanningReport;
    },
    async getAllWeekDepartures(
      { rootState, commit },
      { initialDate, isDraft }
    ) {
      const { data } = await apollo.client.query({
        query: ALL_WEEK_DEPARTURES,
        variables: {
          filter: {
            workKey: rootState.work.work.key,
            initialDate: initialDate,
          },
        },
      });

      let weekDepartures = JSON.parse(JSON.stringify(data.allWeekDepartures));
      weekDepartures = weekDepartures.sort((a, b) => a.sort - b.sort);

      commit(
        isDraft ? "setDraftWeekDepartures" : "setWeekDepartures",
        weekDepartures
      );
    },
    async createWeekDeparture({ commit, rootState }, { weekDeparture }) {
      const { data } = await apollo.client.mutate({
        mutation: CREATE_WEEK_DEPARTURE,
        variables: {
          input: {
            ...weekDeparture,
            workKey: rootState.work.work.key,
          },
        },
      });

      commit("addWeekDeparture", data.createWeekDeparture);
    },
    async updateWeekDeparture({ commit, state, rootState }, { weekDeparture }) {
      const oldWeekDeparture = state.weekDepartures.find(
        (wd) => wd.key === weekDeparture.key
      );

      const { data } = await apollo.client.mutate({
        mutation: UPDATE_WEEK_DEPARTURE,
        variables: {
          input: {
            ...weekDeparture,
          },
        },
      });

      commit("updateWeekDeparture", data.updateWeekDeparture);

      if (weekDeparture.departureKey || weekDeparture.userKey) {
        let data = {};

        if (weekDeparture.departureKey) {
          const departure = rootState.departure.departures.find(
            (d) => d.key === weekDeparture.departureKey
          );

          data.departure = { key: departure.key, name: departure.name };
        }

        if (weekDeparture.userKey) {
          const user = rootState.member.members.find(
            (m) => m.key === weekDeparture.userKey
          );

          data.user = { ...user };
        }

        const schedules = (state.schedule?.schedule || []).filter(
          (s) => s.departure.key === oldWeekDeparture.departureKey
        );

        schedules.forEach((s) => {
          commit("updateSchedule", {
            key: s.key,
            ...data,
          });
        });
      }
    },
    updateDraftWeekDeparture({ commit }, { weekDeparture }) {
      commit("updateDraftWeekDeparture", { ...weekDeparture });
    },
    async deleteWeekDeparture({ commit, state }, { key }) {
      const oldWeekDeparture = state.weekDepartures.find(
        (wd) => wd.key === key
      );

      await apollo.client.mutate({
        mutation: DELETE_WEEK_DEPARTURE,
        variables: {
          key: key,
        },
      });

      commit("deleteWeekDeparture", key);

      const schedules = (state.schedule?.schedule || []).filter(
        (s) => s.departure.key === oldWeekDeparture.departureKey
      );
      schedules.forEach((s) => {
        commit("deleteSchedule", s.key);
      });
    },
    async generateWeekDepartures({ commit, rootState }, { initialDate }) {
      const { data } = await apollo.client.mutate({
        mutation: GENERATE_WEEK_DEPARTURES,
        variables: {
          input: {
            initialDate,
            workKey: rootState.work.work.key,
          },
        },
      });

      commit("setWeekDepartures", data.generateWeekDepartures);
    },
  },
};

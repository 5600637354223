import { useApolloClient } from "../../composables/useApolloClient";
import { ALL_SECTORS } from "../../graphql/queries";
import {
  CREATE_SECTOR,
  UPDATE_SECTOR,
  CREATE_SECTOR_DEPARTURE_ADVANCE,
  UPDATE_SECTOR_DEPARTURE_ADVANCE,
  IMPORT_SECTOR_DEPARTURE_ADVANCES,
} from "../../graphql/mutations";

const apollo = useApolloClient();

export default {
  state: {
    sector: null,
    sectors: null,
  },
  getters: {
    getSector(state) {
      return state.sector;
    },
    getSectors(state) {
      return state.sectors;
    },
  },
  mutations: {
    setSubcontractor(state, payload) {
      state.sector = payload;
    },
    setSectors(state, payload) {
      state.sectors = payload;
    },
    addSector(state, payload) {
      state.sectors = (state.sectors || []).concat(payload);
    },
    updateSector(state, payload) {
      const subcontractorInd = state.sectors.findIndex(
        (m) => m.key === payload.key
      );
      state.sectors[subcontractorInd] = payload;
    },
    addSectorDepartureAdvance(state, payload) {
      const sectorInd = state.sectors.findIndex(
        (m) => m.key === payload.sectorKey
      );
      state.sectors[sectorInd].sectorDepartureAdvances = (
        state.sectors[sectorInd].sectorDepartureAdvances || []
      ).concat(payload);
    },
    updateSectorDepartureAdvance(state, payload) {
      const sectorInd = state.sectors.findIndex(
        (m) => m.key === payload.sectorKey
      );
      const subdepartureInd = state.sectors[
        sectorInd
      ].sectorDepartureAdvances.findIndex((m) => m.key === payload.key);
      state.sectors[sectorInd].sectorDepartureAdvances[subdepartureInd] =
        payload;
    },
    deleteSectorDepartureAdvance(state, payload) {
      const sectorInd = state.departures.findIndex(
        (m) => m.key === payload.sectorKey
      );
      state.sectors[sectorInd].sectorDepartureAdvances = state.sectors[
        sectorInd
      ].sectorDepartureAdvances.filter((r) => r.key !== payload.key);
    },
    deleteSubcontractor(state, payload) {
      state.sectors = state.sectors.filter((r) => r.key !== payload);
    },
  },
  actions: {
    async getAllSectors({ commit, rootState }) {
      const { data } = await apollo.client.query({
        query: ALL_SECTORS,
        variables: {
          filter: {
            workKey: rootState.work.work.key,
          },
          perPage: 500,
        },
      });

      let sectors = JSON.parse(JSON.stringify(data.allSectors.sectors));
      commit("setSectors", sectors);
    },
    async createSector({ commit, rootState }, { sector }) {
      const { data } = await apollo.client.mutate({
        mutation: CREATE_SECTOR,
        variables: {
          input: {
            ...sector,
            workKey: rootState.work.work.key,
          },
        },
      });

      commit("addSector", data.createSector);
    },
    async updateSector({ commit }, { sector }) {
      const { data } = await apollo.client.mutate({
        mutation: UPDATE_SECTOR,
        variables: {
          input: sector,
        },
      });

      commit("updateSector", data.updateSector);
    },
    async createSectorDepartureAdvance(
      { rootState, commit },
      { sectorDepartureAdvance }
    ) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_SECTOR_DEPARTURE_ADVANCE,
          variables: {
            input: {
              ...sectorDepartureAdvance,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("addSectorDepartureAdvance", data.createSectorDepartureAdvance);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateSectorDepartureAdvance({ commit }, { sectorDepartureAdvance }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_SECTOR_DEPARTURE_ADVANCE,
          variables: {
            input: sectorDepartureAdvance,
          },
        });

        commit(
          "updateSectorDepartureAdvance",
          data.updateSectorDepartureAdvance
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async importSectorDepartureAdvance({ rootState }, { file }) {
      try {
        const body = new FormData();
        body.append(
          "operations",
          JSON.stringify({
            query: IMPORT_SECTOR_DEPARTURE_ADVANCES,
            variables: {
              input: {
                workKey: rootState.work.work.key,
                file: null,
              },
            },
          })
        );
        body.append("map", '{ "0": [ "variables.input.file" ]}');
        body.append("0", file);

        const response = await fetch(process.env.VUE_APP_GRAPHQL_BASE_URL, {
          body: body,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "POST",
        });

        const data = await response.json();
        if (data.errors) throw data.errors[0];

        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    redirect: "/users",
  },
  {
    path: "/admin",
    redirect: "/admin/users",
  },
  {
    path: "/admin/users",
    name: "admin-users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/admin/users/Users.vue"),
    meta: {
      authorizedRoles: ["SuperAdmin"],
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login/Login.vue"),
    meta: {
      authorizedRoles: ["anonymous"],
    },
  },
  {
    path: "/confirm-email",
    name: "confirm-email",
    component: () =>
      import(
        /* webpackChunkName: "confirm-email" */ "../views/auth/confirmEmail/ConfirmEmail.vue"
      ),
    meta: {
      authorizedRoles: ["anonymous"],
    },
  },
  {
    path: "/profile/change-password",
    name: "change-password",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "../views/auth/settings/ChangePassword.vue"
      ),
    meta: {
      authorizedRoles: ["SuperAdmin", "Admin", "Worker"],
    },
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/home/Home.vue"),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/works/:key/profile",
    name: "work-profile",
    component: () =>
      import(/* webpackChunkName: "work-profile" */ "../views/work/Work.vue"),
    meta: {
      authorizedRoles: ["Admin"],
    },
    async beforeEnter(to, from, next) {
      const currentWork = store.getters.getWork;
      if (!currentWork || currentWork.key !== to.params.key)
        await store.dispatch("setWork", { key: to.params.key });

      if (store.getters.getWork) {
        next();
      } else {
        next("/home");
      }
    },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/welcome/Welcome.vue"),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
    async beforeEnter(to, from, next) {
      let workSelected = store.getters.getWork;
      if (workSelected) {
        next();
      } else {
        next("/home");
      }
    },
  },
  {
    path: "/configurations/users",
    name: "configurations-users",
    component: () =>
      import(
        /* webpackChunkName: "configurations-users" */ "../views/configurations/users/Users.vue"
      ),
    meta: {
      authorizedRoles: ["Admin"],
    },
  },
  {
    path: "/configurations/workers",
    name: "configurations-workers",
    component: () =>
      import(
        /* webpackChunkName: "configurations-workers" */ "../views/configurations/workers/Workers.vue"
      ),
    meta: {
      authorizedRoles: ["Admin"],
    },
  },
  {
    path: "/configurations/roles",
    name: "configurations-roles",
    component: () =>
      import(
        /* webpackChunkName: "configurations-roles" */ "../views/configurations/roles/Roles.vue"
      ),
    meta: {
      authorizedRoles: ["Admin"],
    },
  },
  {
    path: "/configurations/sectors",
    name: "configurations-sectors",
    component: () =>
      import(
        /* webpackChunkName: "configurations-sectors" */ "../views/configurations/sectors/Sectors.vue"
      ),
    meta: {
      authorizedRoles: ["Admin"],
    },
  },
  {
    path: "/configurations/categories",
    name: "configurations-categories",
    component: () =>
      import(
        /* webpackChunkName: "configurations-categories" */ "../views/configurations/categories/Categories.vue"
      ),
    meta: {
      authorizedRoles: ["Admin"],
    },
  },
  {
    path: "/configurations/subcontractors",
    name: "configurations-subcontractors",
    component: () =>
      import(
        /* webpackChunkName: "configurations-subcontractors" */ "../views/configurations/subcontractors/Subcontractors.vue"
      ),
    meta: {
      authorizedRoles: ["Admin"],
    },
  },
  {
    path: "/configurations/jornal",
    name: "configurations-jornal",
    component: () =>
      import(
        /* webpackChunkName: "configurations-jornal" */ "../views/configurations/jornal/Jornal.vue"
      ),
    meta: {
      authorizedRoles: ["Admin"],
    },
  },
  {
    path: "/tasker/departures",
    name: "tasker-departures",
    component: () =>
      import(
        /* webpackChunkName: "tasker-departures" */ "../views/tasker/departures/Departures.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/tasker/groups",
    name: "tasker-groups",
    component: () =>
      import(
        /* webpackChunkName: "tasker-groups" */ "../views/tasker/groups/Groups.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/tasker/report",
    name: "tasker-report",
    component: () =>
      import(
        /* webpackChunkName: "tasker-report" */ "../views/tasker/reports/Report.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/productivity/report",
    name: "productivity-report",
    component: () =>
      import(
        /* webpackChunkName: "productivity-report" */ "../views/productivity/reports/Report.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/productivity/advances",
    name: "productivity-advances",
    component: () =>
      import(
        /* webpackChunkName: "productivity-advances" */ "../views/productivity/advances/Advances.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/planning/dashboard",
    name: "planning-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "planning-dashboard" */ "../views/planning/dashboard/Dashboard.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/planning/report",
    name: "planning-report",
    component: () =>
      import(
        /* webpackChunkName: "planning-report" */ "../views/planning/reports/Report.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/planning/compliance",
    name: "planning-compliance",
    component: () =>
      import(
        /* webpackChunkName: "planning-compliance" */ "../views/planning/compliance/Compliance.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/planning/restrictions",
    name: "planning-restrictions",
    component: () =>
      import(
        /* webpackChunkName: "planning-restrictions" */ "../views/planning/restrictions/Restrictions.vue"
      ),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/plans/2d",
    name: "plans-2d",
    component: () =>
      import(/* webpackChunkName: "plans-2d" */ "../views/plans/2d.vue"),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
  {
    path: "/plans/3d",
    name: "plans-3d",
    component: () =>
      import(/* webpackChunkName: "plans-3d" */ "../views/plans/3d.vue"),
    meta: {
      authorizedRoles: ["Admin", "Worker"],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Provisional
  try {
    let authorizedRoles = to.meta.authorizedRoles || [];

    let isAuthenticated = store.getters.getIsAuthenticated;
    if (!isAuthenticated) await store.dispatch("currentUser");

    let user = store.getters.getUserAuthenticated;

    if (!authorizedRoles.includes(user ? user.type : "anonymous")) {
      if (!user) {
        next("/login");
      } else {
        if (user && user.type === "SuperAdmin") {
          next("/admin");
        } else {
          next("/home");
        }
      }
    } else {
      to.meta.user = user;
      next();
    }
  } catch (error) {
    // Provisional
    await store.dispatch("logout");
  }
});

export default router;

import { useApolloClient } from "../../composables/useApolloClient";
import { ALL_RESTRICTIONS } from "../../graphql/queries";
import {
  CREATE_RESTRICTION,
  UPDATE_RESTRICTION,
  DELETE_RESTRICTION,
} from "../../graphql/mutations";

const apollo = useApolloClient();

export default {
  state: {
    restrictions: null,
    allRestrictions: {},
    allRestrictionsBySchedule: {},
    restrictionStatus: {
      Raised: "Levantada",
      ForLifting: "Por levantar",
    },
  },
  getters: {
    getRestrictions(state) {
      return state.restrictions;
    },
    getRestrictionStatus(state) {
      return state.restrictionStatus;
    },
  },
  mutations: {
    setRestrictions(state, payload) {
      state.restrictions = payload;
    },
    addRestriction(state, payload) {
      state.restrictions.unshift(payload);
    },
    updateRestriction(state, payload) {
      if (!state.restrictions) return;

      const restrictionInd = state.restrictions.findIndex(
        (m) => m.key === payload.key
      );
      if (restrictionInd >= 0) state.restrictions[restrictionInd] = payload;
    },
    deleteRestriction(state, payload) {
      state.restrictions = state.restrictions.filter((g) => g.key !== payload);
    },
    setAllRestrictionsByDate(state, payload) {
      state.allRestrictions[payload.date] = payload.restrictions;
    },
    setAllRestrictionsBySchedule(state, payload) {
      state.allRestrictionsBySchedule[payload.scheduleKey] =
        payload.restrictions;
    },
  },
  actions: {
    async getAllRestrictions({ commit, rootState }, payload) {
      commit("setRestrictions", null);

      let filter = {
        workKey: rootState.work.work.key,
      };

      if (payload?.liftingDateFrom)
        filter.liftingDateFrom = payload.liftingDateFrom;
      if (payload?.liftingDateTo) filter.liftingDateTo = payload.liftingDateTo;
      if (payload?.typeKey) filter.typeKey = payload.typeKey;
      if (payload?.userKey) filter.userKey = payload.userKey;
      if (payload?.status) filter.status = payload.status;
      if (payload?.q) filter.q = payload.q;

      let restrictions;

      const { data } = await apollo.client.query({
        query: ALL_RESTRICTIONS,
        variables: {
          filter: filter,
          perPage: 10000,
        },
      });

      restrictions = JSON.parse(
        JSON.stringify(data.allRestrictions.restrictions)
      );

      if (!payload.noSet) commit("setRestrictions", restrictions);

      return restrictions;
    },
    async createRestriction({ commit, rootState }, { restriction }) {
      const { data } = await apollo.client.mutate({
        mutation: CREATE_RESTRICTION,
        variables: {
          input: {
            ...restriction,
            workKey: rootState.work.work.key,
          },
        },
      });

      commit("addRestriction", data.createRestriction);

      return data.createRestriction;
    },
    async updateRestriction({ commit }, { restriction }) {
      const { data } = await apollo.client.mutate({
        mutation: UPDATE_RESTRICTION,
        variables: {
          input: restriction,
        },
      });

      commit("updateRestriction", data.updateRestriction);

      return data.updateRestriction;
    },
    async deleteRestriction({ commit }, { restrictionKey }) {
      await apollo.client.mutate({
        mutation: DELETE_RESTRICTION,
        variables: {
          key: restrictionKey,
        },
      });

      commit("deleteRestriction", restrictionKey);
    },
    resetAllRestrictions({ commit }) {
      commit("setRestrictions", null);
    },
    // ------- REMOVE
    saveAllRestrictionsBySchedule({ commit, state }, { scheduleKey }) {
      commit("setAllRestrictionsBySchedule", {
        scheduleKey,
        restrictions: state.restrictions,
      });
    },
    saveAllRestrictionsByScheduleGroup(
      { commit, state },
      { schedules, restrictions }
    ) {
      let allRestrictions;
      for (let schedule of schedules) {
        allRestrictions = state.allRestrictionsBySchedule[schedule.key] || [];
        allRestrictions.push(
          ...restrictions.filter((r) => r.scheduleKey === schedule.key)
        );
        commit("setAllRestrictionsBySchedule", {
          scheduleKey: schedule.key,
          restrictions: allRestrictions,
        });
      }
    },
  },
};

import { createStore } from "vuex";
import user from "./modules/user";
import work from "./modules/work";
import role from "./modules/role";
import member from "./modules/member";
import speciality from "./modules/speciality";
import departure from "./modules/departure";
import group from "./modules/group";
import schedule from "./modules/schedule";
import type from "./modules/type";
import restriction from "./modules/restriction";
import plan from "./modules/plan";
import workHour from "./modules/workHour";
import report from "./modules/report";
import category from "./modules/category";
import subcontractor from "./modules/subcontractor";
import departureIp from "./modules/departureIp";
import sector from "./modules/sector";
import jornal from "./modules/jornal";
import taskerConfig from "./modules/taskerConfig";

export default createStore({
  state: {
    showStepper: false,
    showMenu: false,
  },
  getters: {
    getShowStepper(state) {
      return state.showStepper;
    },
    getShowMenu(state) {
      return state.showMenu;
    },
  },
  mutations: {
    setShowStepper(state, payload) {
      state.showStepper = payload;
    },
    setShowMenu(state, payload) {
      state.showMenu = payload;
    },
  },
  actions: {
    toggleShowStepper({ commit }, { toggle }) {
      commit("setShowStepper", toggle);
    },
    toggleShowMenu({ commit, state }, toggle) {
      commit("setShowMenu", toggle === undefined ? !state.showMenu : toggle);
    },
  },
  modules: {
    user,
    work,
    role,
    member,
    speciality,
    departure,
    group,
    schedule,
    type,
    restriction,
    plan,
    workHour,
    report,
    category,
    subcontractor,
    departureIp,
    sector,
    jornal,
    taskerConfig,
  },
});

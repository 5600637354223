import { useApolloClient } from "../../composables/useApolloClient";
import { ALL_JORNAL } from "../../graphql/queries";
import { ENABLE_JORNAL, UPDATE_JORNAL } from "../../graphql/mutations";

const apollo = useApolloClient();

export default {
  state: {
    jornal: null,
  },
  getters: {
    getJornal(state) {
      return state.jornal;
    },
  },
  mutations: {
    setJornal(state, payload) {
      state.jornal = payload;
    },
    updateJornal(state, payload) {
      const jornalInd = state.jornal.findIndex((m) => m.key === payload.key);
      state.jornal[jornalInd] = payload;
    },
  },
  actions: {
    async getAllJornal({ commit, rootState }) {
      const { data } = await apollo.client.query({
        query: ALL_JORNAL,
        variables: {
          filter: {
            workKey: rootState.work.work.key,
          },
        },
      });

      let jornal = JSON.parse(JSON.stringify(data.allJornal));
      commit("setJornal", jornal);
    },
    async enableJornal({ commit, rootState }) {
      const { data } = await apollo.client.mutate({
        mutation: ENABLE_JORNAL,
        variables: {
          input: {
            workKey: rootState.work.work.key,
          },
        },
      });

      commit("setJornal", data.enableJornal);
    },
    async updateJornal({ commit }, { jornal }) {
      const { data } = await apollo.client.mutate({
        mutation: UPDATE_JORNAL,
        variables: {
          input: jornal,
        },
      });

      commit("updateJornal", data.updateJornal);
    },
  },
};

import { useApolloClient } from "../../composables/useApolloClient";
import { ALL_WORK_HOURS } from "../../graphql/queries";
import {
  AUTHORIZE_WORK_HOUR_ITEM,
  DELETE_WORK_HOUR_ITEM,
  IMPORT_TASKER_WEEKLY,
} from "../../graphql/mutations";

const apollo = useApolloClient();

export default {
  state: {
    workHours: null,
  },
  getters: {
    getWorkHours(state) {
      return state.workHours;
    },
  },
  mutations: {
    setWorkHours(state, payload) {
      state.workHours = payload;
    },
    updateWorkHourItem(state, payload) {
      const workHourInd = state.workHours.findIndex(
        (g) => g.items[0].key === payload.key
      );
      state.workHours[workHourInd].items[0].isAuthorized = payload.isAuthorized;
    },
    deleteWorkHourItem(state, payload) {
      state.workHours = state.workHours.filter(
        (r) => r.items[0].key !== payload
      );
    },
  },
  actions: {
    async getAllWorkHours(
      { commit, rootState },
      { filter, departureKey, groupKey, sectorKey, foremanKey }
    ) {
      try {
        const variables = {
          filter: {
            ...filter,
            workKey: rootState.work.work.key,
          },
          perPage: 500,
        };
        if (departureKey) variables.departureKey = departureKey;
        if (groupKey) variables.groupKey = groupKey;
        if (sectorKey) variables.sectorKey = sectorKey;

        const { data } = await apollo.client.query({
          query: ALL_WORK_HOURS,
          variables,
        });

        const allWorkHours = JSON.parse(JSON.stringify(data.allWorkHours));
        const allWorkHoursFormatted = [];

        allWorkHours.forEach((workHour) => {
          if (workHour.items?.length) {
            workHour.items.forEach((item) => {
              if (!foremanKey || item.group.foreman.key === foremanKey) {
                allWorkHoursFormatted.push({ ...workHour, items: [item] });
              }
            });
          }
        });

        commit("setWorkHours", allWorkHoursFormatted);

        return allWorkHoursFormatted;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async authorizeWorkHourItem({ commit }, { workHourItemKey }) {
      try {
        await apollo.client.mutate({
          mutation: AUTHORIZE_WORK_HOUR_ITEM,
          variables: { key: workHourItemKey },
        });

        commit("updateWorkHourItem", {
          key: workHourItemKey,
          isAuthorized: true,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteWorkHourItem({ commit }, { workHourItemKey }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: DELETE_WORK_HOUR_ITEM,
          variables: { key: workHourItemKey },
        });

        if (data.deleteWorkHourItem)
          commit("deleteWorkHourItem", workHourItemKey);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async importTaskerWeekly({ rootState }, { file, date }) {
      const body = new FormData();
      body.append(
        "operations",
        JSON.stringify({
          query: IMPORT_TASKER_WEEKLY,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              dateFrom: date,
              file: null,
            },
          },
        })
      );
      body.append("map", '{ "0": [ "variables.input.file" ]}');
      body.append("0", file);

      const response = await fetch(process.env.VUE_APP_GRAPHQL_BASE_URL, {
        body: body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "POST",
      });

      const data = await response.json();
      if (data.errors) throw data.errors[0];

      return data;
    },
    resetWorkHours({ commit }) {
      commit("setWorkHours", null);
    },
  },
};

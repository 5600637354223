import { useApolloClient } from "../../composables/useApolloClient";
import {
  CREATE_DEPARTURE,
  UPDATE_DEPARTURE,
  DELETE_DEPARTURE,
  IMPORT_DEPARTURES,
} from "../../graphql/mutations";
import { ALL_DEPARTURES } from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    departures: null,
  },
  getters: {
    getDepartures(state) {
      return state.departures;
    },
    getDeparturesMap(state) {
      return state.departures.reduce((prev, next) => {
        const { key } = next;
        prev[key] = next;
        return prev;
      }, {});
    },
    getDeparturesIsToCalculateIp(state) {
      return (state.departures || []).filter((d) => d.isToCalculateIp);
    },
  },
  mutations: {
    setDepartures(state, payload) {
      state.departures = payload;
    },
    addDeparture(state, payload) {
      state.departures = state.departures.concat(payload);
    },
    updateDeparture(state, payload) {
      const departureInd = state.departures.findIndex(
        (m) => m.key === payload.key
      );
      state.departures[departureInd] = payload;
    },
    deleteDeparture(state, payload) {
      state.departures = state.departures.filter((r) => r.key !== payload);
    },
    addSubDeparture(state, payload) {
      const departureInd = state.departures.findIndex(
        (m) => m.key === payload.parentDepartureKey
      );
      state.departures[departureInd].subdepartures = (
        state.departures[departureInd].subdepartures || []
      ).concat(payload);
    },
    updateSubDeparture(state, payload) {
      const departureInd = state.departures.findIndex(
        (m) => m.key === payload.parentDepartureKey
      );
      const subdepartureInd = state.departures[
        departureInd
      ].subdepartures.findIndex((m) => m.key === payload.key);
      state.departures[departureInd].subdepartures[subdepartureInd] = payload;
    },
    deleteSubDeparture(state, payload) {
      const departureInd = state.departures.findIndex(
        (m) => m.key === payload.parentDepartureKey
      );
      state.departures[departureInd].subdepartures = state.departures[
        departureInd
      ].subdepartures.filter((r) => r.key !== payload.key);
    },
  },
  actions: {
    async getAllDepartures({ commit, rootState }, payload) {
      try {
        let filter = {
          workKey: rootState.work.work.key,
        };

        if (payload?.includeSubdepartures) filter.includeSubdepartures = true;

        const { data } = await apollo.client.query({
          query: ALL_DEPARTURES,
          variables: {
            filter,
            perPage: 500,
          },
        });

        commit(
          "setDepartures",
          JSON.parse(JSON.stringify(data.allDepartures.departures))
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async searchAllDepartures({ rootState }, { qSearch = "" }) {
      try {
        let filter = {
          workKey: rootState.work.work.key,
        };
        if (qSearch) filter.q = qSearch;

        const { data } = await apollo.client.query({
          query: ALL_DEPARTURES,
          variables: {
            filter: filter,
            perPage: 500,
          },
        });

        return JSON.parse(JSON.stringify(data.allDepartures.departures));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createDeparture({ commit, rootState }, { departure }) {
      try {
        if (!departure.budget) departure.budget = null;

        const { data } = await apollo.client.mutate({
          mutation: CREATE_DEPARTURE,
          variables: {
            input: {
              ...departure,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("addDeparture", data.createDeparture);
        return data.createDeparture;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createSubDeparture({ rootState, commit }, { subDeparture }) {
      try {
        if (!subDeparture.budget) subDeparture.budget = null;

        const { data } = await apollo.client.mutate({
          mutation: CREATE_DEPARTURE,
          variables: {
            input: {
              ...subDeparture,
              isSubDeparture: true,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("addSubDeparture", data.createDeparture);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateDeparture({ commit }, { departure }) {
      try {
        if (!departure.budget) departure.budget = null;

        const { data } = await apollo.client.mutate({
          mutation: UPDATE_DEPARTURE,
          variables: {
            input: departure,
          },
        });

        commit("updateDeparture", data.updateDeparture);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateSubDeparture({ commit }, { subDeparture }) {
      try {
        if (!subDeparture.budget) subDeparture.budget = null;

        const { data } = await apollo.client.mutate({
          mutation: UPDATE_DEPARTURE,
          variables: {
            input: subDeparture,
          },
        });

        commit("updateSubDeparture", data.updateDeparture);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteDeparture({ commit }, { departureKey }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: DELETE_DEPARTURE,
          variables: {
            key: departureKey,
          },
        });

        if (data.deleteDeparture) commit("deleteDeparture", departureKey);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteSubDeparture({ commit }, { subDepartureKey, departureKey }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: DELETE_DEPARTURE,
          variables: {
            key: subDepartureKey,
          },
        });

        if (data.deleteDeparture)
          commit("deleteSubDeparture", {
            key: subDepartureKey,
            parentDepartureKey: departureKey,
          });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async importDepartures({ rootState }, { file }) {
      try {
        const body = new FormData();
        body.append(
          "operations",
          JSON.stringify({
            query: IMPORT_DEPARTURES,
            variables: {
              input: {
                workKey: rootState.work.work.key,
                file: null,
              },
            },
          })
        );
        body.append("map", '{ "0": [ "variables.input.file" ]}');
        body.append("0", file);

        const response = await fetch(process.env.VUE_APP_GRAPHQL_BASE_URL, {
          body: body,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "POST",
        });

        const data = await response.json();
        if (data.errors) throw data.errors[0];

        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetDepartures({ commit }) {
      commit("setDepartures", null);
    },
  },
};

import { useApolloClient } from "../../composables/useApolloClient";
import { ALL_TYPES } from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    typesNonCompliance: null,
    typesRestriction: null,
    typesRestrictionMap: null,
  },
  getters: {
    getTypesNonCompliance(state) {
      return state.typesNonCompliance;
    },
    getTypesRestriction(state) {
      return state.typesRestriction;
    },
    getTypesRestrictionMap(state) {
      return state.typesRestrictionMap;
    },
  },
  mutations: {
    setTypesNonCompliance(state, payload) {
      state.typesNonCompliance = payload;
    },
    setTypesRestriction(state, payload) {
      state.typesRestriction = payload;
    },
    setTypesRestrictionMap(state, payload) {
      state.typesRestrictionMap = payload;
    },
  },
  actions: {
    async getAllTypes({ commit }, { type }) {
      try {
        const { data } = await apollo.client.query({
          query: ALL_TYPES,
          variables: {
            filter: {
              type: type,
            },
            perPage: 200,
          },
        });

        const types = JSON.parse(JSON.stringify(data.allTypes.types));

        if (type === "Restriction") {
          const typesMap = types.reduce((prev, next) => {
            const { key } = next;
            prev[key] = next;
            return prev;
          }, {});

          commit("setTypesRestriction", types);
          commit("setTypesRestrictionMap", typesMap);
        } else {
          commit("setTypesNonCompliance", types);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { reactive } from "vue";
import { useStore } from "vuex";

const store = useStore();

export function useApolloClient() {
  const apollo = reactive({
    client: null,
  });

  const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_BASE_URL,
    fetch: customFetch,
  });

  apollo.client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

  return apollo;
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const customFetch = (uri, options) => {
  let refreshingPromise = null;

  var initialRequest = fetch(uri, options);

  return initialRequest
    .then((response) => {
      return response.text();
    })
    .then((json) => {
      const jsonToParse = JSON.parse(json);
      if (
        jsonToParse &&
        jsonToParse.errors &&
        jsonToParse.errors[0] &&
        jsonToParse.errors[0].message === "access denied"
      ) {
        if (!refreshingPromise) {
          refreshingPromise = fetch(process.env.VUE_APP_GRAPHQL_BASE_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              query: `
                mutation refreshToken($refreshToken: String!) {
                  refreshToken(refreshToken: $refreshToken) {
                    accessToken
                    refreshToken
                  }
                }
              `,
              variables: {
                refreshToken: localStorage.getItem("refresh_token"),
              },
            }),
          }).then((refresh_token_repsonse) => {
            if (refresh_token_repsonse.ok) {
              return refresh_token_repsonse.json().then((refreshJSON) => {
                if (!refreshJSON?.data?.refreshToken)
                  throw new Error("Token de sesión expirado");
                localStorage.setItem(
                  "token",
                  refreshJSON.data.refreshToken.accessToken
                );
                localStorage.setItem(
                  "refresh_token",
                  refreshJSON.data.refreshToken.refreshToken
                );

                return refreshJSON.data.refreshToken.accessToken;
              });
            } else {
              store.dispatch("logout");
            }
          });
        }

        return refreshingPromise.then((newAccessToken) => {
          refreshingPromise = null;

          options.headers.authorization = `Bearer ${newAccessToken}`;

          return fetch(uri, options);
        });
      }

      var result = {};
      result.ok = true;
      result.text = () =>
        new Promise(function (resolve) {
          resolve(json);
        });
      return result;
    })
    .catch((error) => {
      if (error.message === "The user aborted a request.") return;

      window.location.href = "/login";
      // store.dispatch("logout");
      // if (!store.getters.userAuthenticated) window.location.href = "/login";
    });
};

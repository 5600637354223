import { useApolloClient } from "../../composables/useApolloClient";
import {
  CREATE_TASKER_DAILY_CONFIG,
  UPDATE_TASKER_DAILY_CONFIG,
  HANDLE_TASKER_DAILY_CONFIG_BY_WEEK,
} from "../../graphql/mutations";
import { FIND_TASKER_DAILY_CONFIG } from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    taskerDailyConfig: null,
  },
  getters: {
    getTaskerDailyConfig(state) {
      return state.taskerDailyConfig;
    },
  },
  mutations: {
    setTaskerDailyConfig(state, payload) {
      state.taskerDailyConfig = payload;
    },
  },
  actions: {
    async findTaskerDailyConfig({ commit, rootState }, { date }) {
      const { data } = await apollo.client.query({
        query: FIND_TASKER_DAILY_CONFIG,
        variables: {
          filter: {
            workKey: rootState.work.work.key,
            date,
          },
        },
      });

      commit("setTaskerDailyConfig", data.findTaskerDailyConfig);
    },
    async createTaskerDailyConfig({ commit, rootState }, { config }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_TASKER_DAILY_CONFIG,
          variables: {
            input: {
              workKey: rootState.work.work.key,
              ...config,
            },
          },
        });

        commit("setTaskerDailyConfig", data.createTaskerDailyConfig);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateTaskerDailyConfig({ commit, state }, { config }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: UPDATE_TASKER_DAILY_CONFIG,
          variables: {
            input: config,
          },
        });

        commit("setTaskerDailyConfig", {
          ...state.taskerDailyConfig,
          ...data.updateTaskerDailyConfig,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    resetTaskerDailyConfig({ commit }) {
      commit("setTaskerDailyConfig", null);
    },
    async handleTaskerDailyConfigByWeek(
      { commit, state, rootState },
      { config }
    ) {
      try {
        await apollo.client.mutate({
          mutation: HANDLE_TASKER_DAILY_CONFIG_BY_WEEK,
          variables: {
            input: {
              ...config,
              workKey: rootState.work.work.key,
            },
          },
        });

        commit("setTaskerDailyConfig", {
          ...state.taskerDailyConfig,
          ...config,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

import { useApolloClient } from "../../composables/useApolloClient";
import {
  LOGIN,
  FORGOT_PASSWORD,
  CONFIRM_EMAIL,
  CHANGE_PASSWORD,
  CREATE_USER,
  DELETE_USER,
} from "../../graphql/mutations";
import {
  ME,
  PERMISSIONS,
  ALL_WORK_USERS,
  ALL_USERS_ADMINS,
  FIND_USERS_BY_EMAIL,
} from "../../graphql/queries";

const apollo = useApolloClient();

export default {
  state: {
    isAuthenticated: false,
    userAuthenticated: null,
    userPermissions: null,
    users: null,
  },
  getters: {
    getIsAuthenticated(state) {
      return state.isAuthenticated;
    },
    getUserAuthenticated(state) {
      return state.userAuthenticated;
    },
    getUserPermissions(state) {
      return state.userPermissions;
    },
    getUsers(state) {
      return state.users;
    },
  },
  mutations: {
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setUserAuthenticated(state, payload) {
      state.userAuthenticated = payload;
    },
    setUserPermissions(state, payload) {
      state.userPermissions = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    addUser(state, payload) {
      state.users.push(payload);
    },
    deleteUser(state, payload) {
      state.users = state.users.filter((u) => u.key !== payload);
    },
  },
  actions: {
    async currentUser({ commit, dispatch }) {
      try {
        if (!localStorage.getItem("token")) return;

        const { data } = await apollo.client.query({ query: ME });

        commit("setIsAuthenticated", true);
        commit("setUserAuthenticated", data.Me);

        dispatch(
          "currentWorks",
          { data: data.Me.workConnection },
          { root: true }
        );
        commit("setWorks", data.Me.workConnection.works, { root: true });

        const workKey = localStorage.getItem("work");
        if (workKey) dispatch("setWork", { key: workKey }, { root: true });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async setUserPermissions({ commit, state }, { workKey }) {
      try {
        const { data } = await apollo.client.query({
          query: PERMISSIONS,
          variables: {
            workKey: workKey,
          },
        });

        commit("setUserAuthenticated", {
          ...state.userAuthenticated,
          speciality: data.Me.speciality,
        });

        if (data.Me.currentRole)
          commit("setUserPermissions", data.Me.currentRole.permissions);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async login({ dispatch }, { email, password }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: LOGIN,
          variables: {
            input: { email: email, password: password },
          },
        });

        localStorage.setItem("token", data.login.accessToken);
        localStorage.setItem("refresh_token", data.login.refreshToken);
        dispatch("currentUser");
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    logout({ commit }) {
      apollo.client.resetStore();

      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      commit("setIsAuthenticated", false);
      commit("setUserAuthenticated", null);
    },
    // eslint-disable-next-line
    async forgotPassword({}, { email }) {
      try {
        await apollo.client.mutate({
          mutation: FORGOT_PASSWORD,
          variables: {
            email: email,
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async confirmEmail({}, { hash }) {
      try {
        await apollo.client.mutate({
          mutation: CONFIRM_EMAIL,
          variables: {
            hash: hash,
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async changePassword({}, { oldPassword, password }) {
      try {
        await apollo.client.mutate({
          mutation: CHANGE_PASSWORD,
          variables: {
            input: {
              oldPassword,
              password,
            },
          },
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async createUser({ commit, state }, { user }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: CREATE_USER,
          variables: {
            input: user,
          },
        });

        if (!state.users) commit("setUsers", []);

        commit("addUser", data.createUser);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllUser({ commit }, { type }) {
      try {
        const filter = { userEntity: "User" };
        if (type) filter.type = [type];

        const { data } = await apollo.client.query({
          query: ALL_WORK_USERS,
          variables: {
            filter,
            perPage: 200,
          },
        });

        commit("setUsers", JSON.parse(JSON.stringify(data.allUsers.users)));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getAllUserAdmins({ commit }) {
      try {
        const filter = {
          userEntity: "User",
          type: ["Admin"],
        };

        const { data } = await apollo.client.query({
          query: ALL_USERS_ADMINS,
          variables: {
            filter,
            perPage: 200,
          },
        });

        commit("setUsers", JSON.parse(JSON.stringify(data.allUsers.users)));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line
    async findUserByEmail({}, { email }) {
      try {
        const { data } = await apollo.client.query({
          query: FIND_USERS_BY_EMAIL,
          variables: { email },
        });

        const users = [];

        if (data.findUserByEmail) users.push(data.findUserByEmail);

        return users;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteUser({ commit }, { key }) {
      try {
        const { data } = await apollo.client.mutate({
          mutation: DELETE_USER,
          variables: { key },
        });

        if (data.deleteUser) commit("deleteUser", key);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
